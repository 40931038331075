import { Component } from './types'
export const EFAComponents: Component[] = [
  {
    label: 'D',
    key: 'd',
    componentColor: '#38c172',
    itemColor: '#9cddb3',
    inputColor: '#d2f0dd',
    totalColor: '#9cddb3',
    items: [
      {
        name: 'Mezzanine areas',
        description: 'Mezzanine floor areas intended with permanent access (2.8)'
      }
    ]
  },
  {
    label: 'F',
    key: 'f',
    componentColor: '#3490dc',
    itemColor: '#95c2ed',
    inputColor: '#cfe4f7',
    totalColor: '#95c2ed',
    items: [
      {
        name: 'Projection rooms',
        description: 'Projection rooms (2.11)'
      }
    ]
  },
  {
    label: 'H',
    key: 'h',
    componentColor: '#9561e2',
    itemColor: '#c5a8ef',
    inputColor: '#e4d8f7',
    totalColor: '#c5a8ef',
    items: [
      // {
      //   key: 'a',
      //   name: 'Living room',
      //   description: 'Living rooms areas excluding all internal walls (27.8) but including skirting (27.4) fitted cupboard (27.2) or ventilation / heating grille (27.3)'
      // },
      // {
      //   key: 'a',
      //   name: 'Dining room',
      //   description: 'Dining room areas excluding all internal walls (27.8) but including skirting (27.4) fitted cupboard (27.2) or ventilation / heating grille (27.3)'
      // },
      // {
      //   key: 'c',
      //   name: 'Bedroom',
      //   description: 'Bedroom area (master bedroom, children bedroom, guest, etc) excluding all internal walls (27.8) but including skirting (27.4) fitted cupboard (27.2) or ventilation / heating grille (27.3)'
      // }
    ]
  },
  {
    label: 'I',
    key: 'i',
    componentColor: '#f66d9b',
    itemColor: '#fcb0c9',
    inputColor: '#ffdce6',
    totalColor: '#fcb0c9',
    items: [
      {
        key: 'a',
        name: 'Basements',
        description: 'Basements and underground area (26.1)'
      },
      {
        key: 'b',
        name: 'Galleries',
        description: 'Garages & parkings (2.16)'
      },
      {
        key: 'c',
        name: 'Hallways',
        description: 'Conservatories (2.17)'
      }
    ]
  }
]