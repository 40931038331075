const STANDARD_MEASUREMENT = {
  IPMS: 48
} as const

const IPMS = {
  IPMS1: 69,
  IPMS2: 70,
  IPMS3_1: 71,
  IPMS3_2: 72,
  IPMS4_1: 73,
  IPMS4_2: 74
} as const

export {
  STANDARD_MEASUREMENT,
  IPMS
}