import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { driver } from "driver.js";
import { BehaviorSubject, combineLatest } from "rxjs";
import { currentUser, MadUserOnboarded, User } from "../mad-auth/mad-auth.store";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  private _driver = driver({
    showProgress: true,
    popoverClass: 'interval-driver-theme',
    overlayOpacity: 0.25,
    allowClose: false,
  })

  authUser$ = this.store.select(currentUser)
  helpStepSet$ = new BehaviorSubject<boolean>(false)
  livechatStepSet$ = new BehaviorSubject<boolean>(false)

  constructor(
    private store: Store
  ) {

  }

  init() {
    combineLatest([
      this.authUser$.pipe(filter(value => value != undefined)),
      this.helpStepSet$.asObservable(),
      this.livechatStepSet$.asObservable()
    ]).subscribe(([user, helpStepSet, livechatStepSet]) => {
      if (!user.is_onboarded && helpStepSet && livechatStepSet) {
        this.runDashboardWizard(user)
      }
    })
  }

  run() {
    this.runDashboardWizard()
  }

  private runDashboardWizard(user?: User) {
    this._driver.setConfig({
      showProgress: true,
      popoverClass: 'interval-driver-theme',
      overlayOpacity: 0.25,
      allowClose: false,
      onDestroyed: () => {
        if (!user) return;
        if (user.is_onboarded) return;
        this.store.dispatch(new MadUserOnboarded({ user }))
      },
      steps: [
        {
          popover: {
            title: 'Welcome to InterVal!',
            description: "Let's take a quick tour to help you navigate the platform. Follow the steps to discover key features and get started seamlessly!"
          }
        },
        {
          element: '#help', popover: {
            title: 'Access the Knowledge Base',
            description: 'Need help? Click here to access guides, tutorials, and documentation to answer your questions anytime.',
            side: 'bottom'
          }
        },
        {
          element: '#chat-widget-minimized', popover: {
            title: 'Support & Ticketing',
            description: 'Facing an issue? Use this section to contact support or create a ticket to get assistance quickly',
            side: 'top',
            align: 'end',
            onNextClick: () => {
              this._openMenu('menu-admin')
              this._openMenu('menu-profile')
              this._highlightMenu('agencies')
              this._driver.moveNext()
            }
          }
        },
        {
          element: '#agencies', popover: {
            title: 'Manage Agencies',
            description: 'View and manage all agencies associated with your account. Easily navigate and make updates when needed.',
            side: 'right',
            onNextClick: () => {
              this._unhighlightMenu('agencies')
              this._highlightMenu('users')
              this._driver.moveNext()
            }
          }
        },
        {
          element: '#users', popover: {
            title: 'Manage Your Users',
            description: 'Here, you can view, add, and manage users within your organization, ensuring proper access control',
            side: 'right',
            onNextClick: () => {
              this._unhighlightMenu('users');
              this._closeMenu('menu-profile')
              this._closeMenu('menu-admin')
              this._driver.moveNext()
            }
          }
        },
        {
          element: '#wizard', popover: {
            title: 'Replay This Wizard Anytime',
            description: 'Need a refresher? Click this button at any time to restart this tutorial and navigate through the key features again.',
            side: 'bottom'
          }
        }
       ],
    })
    this._driver.drive()
  }

  private _openProfileSideMenu() {
    const adminMenuHTMLLiElement = document.getElementById('menu-admin');
    if (!adminMenuHTMLLiElement.classList.contains('menu-item-open')) {
      const toggleLink = adminMenuHTMLLiElement.querySelector('.menu-link') as HTMLElement
      toggleLink.click()
    }
    const profileMenuHTMLLiElement = document.getElementById('menu-profile')
    if (!profileMenuHTMLLiElement.classList.contains('menu-item-open')) {
      const toggleLink = profileMenuHTMLLiElement.querySelector('.menu-link') as HTMLElement
      toggleLink.click()
    }
  }

  private _openMenu(id: string) {
    const menuHTMLListElement = document.getElementById(id);
    if (menuHTMLListElement.classList.contains('menu-item-open')) return
    const toggleLink = menuHTMLListElement.querySelector('.menu-link') as HTMLElement
    toggleLink.click();
  }

  private _closeMenu(id: string) {
    const menuHTMLListElement = document.getElementById(id);
    if (!menuHTMLListElement.classList.contains('menu-item-open')) return
    const toggleLink = menuHTMLListElement.querySelector('.menu-link') as HTMLElement
    toggleLink.click();
  }

  private _highlightMenu(id: string) {
    const menuLinkElement = document.querySelector(`#${id} > .inner-menu`) as HTMLElement
    menuLinkElement.style.border = '1px solid red';
  }

  private _unhighlightMenu(id: string) {
    const menuLinkElement = document.querySelector(`#${id} > .inner-menu`) as HTMLElement
    menuLinkElement.style.border = '';
  }
}