import { Component } from './types'
export const GEAComponents: Component[] = [
  {
    label: 'A1',
    key: 'a1',
    componentColor: '#e3342f',
    itemColor: '#f2938e',
    inputColor: '#fbcecc',
    totalColor: '#f2938e',
    items: [
      {
        name: 'Perimiter walls',
        description: 'Perimeter wall thickness and external projections (1.1)'
      }
    ]
  },
  {
    label: 'A2',
    key: 'a2',
    componentColor: '#eb605b',
    itemColor: '#f6a9a5',
    inputColor: '#fcd9d6',
    totalColor: '#f6a9a5',
    items: [
      {
        name: 'Room areas and Internal walss & partitions',
        description: 'Room areas including the ones occupied by internal walls and partitions (1.2)'
      }
    ]
  },
  {
    label: 'B1',
    key: 'b1',
    componentColor: '#f6993f',
    itemColor: '#fbc79a',
    inputColor: '#fee6d2',
    totalColor: '#fbc79a',
    items: [{
      name: 'Columns, chimney breasts, stairwells & others',
      description: 'Columns, piers, chimney breasts, stairwelss, lift-wells and the like (1.3)'
    }]
  },
  {
    label: 'B2',
    key: 'b2',
    componentColor: '#f8b374',
    itemColor: '#fcd6b5',
    inputColor: '#feecdd',
    totalColor: '#fcd6b5',
    items: [{
      name: 'Atria & entrance halls',
      description: 'Atria and entrance halls, with clear height above, measured at base level only (1.4)'
    }]
  },
  {
    label: 'B3',
    key: 'b3',
    componentColor: '#fbcca4',
    itemColor: '#fce2cc',
    inputColor: '#fef3e9',
    totalColor: '#fce2cc',
    items: [{
      name: 'Internal balconies',
      description: 'Internal balconies (1.5)'
    }]
  },
  {
    label: 'C',
    key: 'c',
    componentColor: '#ffed4a',
    itemColor: '#fff5a2',
    inputColor: '#fffbd6',
    totalColor: '#fff5a2',
    items: [
      {
        key: 'a',
        name: 'Vertical circulation area',
        description: 'Structural, raked or stepped floors are to be treated as a level floor measured horizontally (1.6)'
      },
      {
        key: 'b',
        name: 'Floors underneath vertical circulation area',
        description: 'Horizontal floors, whether accessible or not, below structural, raked or stepped floors (1.7)'
      }
    ]
  },
  {
    label: 'D',
    key: 'd',
    componentColor: '#38c172',
    itemColor: '#9cddb3',
    inputColor: '#d2f0dd',
    totalColor: '#9cddb3',
    items: [
      {
        name: 'Mezzanine areas',
        description: 'Mezzanine areas intended for use with permanent access (1.8)'
      }
    ]
  },
  {
    label: 'E',
    key: 'e',
    componentColor: '#4dc0b5',
    itemColor: '#a2ddd5',
    inputColor: '#d6f0ed',
    totalColor: '#a2ddd5',
    items: [
      {
        name: 'Technical services',
        description: 'Lift rooms, plant rooms, fuel stores, tank rooms which are housed in a covered structure of a permanent nature, whether or not above the main roof level (1.9)'
      }
    ]
  },
  {
    label: 'F',
    key: 'f',
    componentColor: '#3490dc',
    itemColor: '#95c2ed',
    inputColor: '#cfe4f7',
    totalColor: '#95c2ed',
    items: [
      {
        name: 'Outbuildings',
        description: 'Outbuildings which share at least one wall with the main building (1.10)'
      }
    ]
  },
  {
    label: 'G',
    key: 'g',
    componentColor: '#6574cd',
    itemColor: '#abb3e4',
    inputColor: '#d8dcf2',
    totalColor: '#abb3e4',
    items: [
      {
        name: 'Loading bays',
        description: 'Loading bays (1.11)'
      }
    ]
  },
  {
    label: 'H',
    key: 'h',
    componentColor: '#9561e2',
    itemColor: '#c5a8ef',
    inputColor: '#e4d8f7',
    totalColor: '#c5a8ef',
    items: [
      {
        key: 'a',
        name: 'Areas with a headroom of less than 1.5m',
        description: 'Areas with a headroom of less than 1.5m (1.12)'
      },
      {
        key: 'b',
        name: 'Underground space',
        description: 'Pavement vaults (1.13)'
      },
      {
        key: 'c',
        name: 'Garages',
        description: 'Garages & Parkings (1.14)'
      },
      {
        key: 'd',
        name: 'Conservatories',
        description: 'Conservatories (1.15)'
      }
    ]
  }
]