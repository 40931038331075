import * as _ from "lodash";
import { FloorColumn, ItemKey } from "./types";

type Component = {
  label: string,
  name: string,
  description?: string,
  key: string,
  subComponents?: SubComponent[],
  items?: ItemKey[],
  labelColor: string,
  nameColor: string,
  ipmsTotalColor?: string
  calculatedColor?: string,
}

type SubComponent = {
  label: string,
  key: string,
  name?: string,
  description?: string,
  sections?: {
    label: string,
    key: string,
    labelColor: string,
    name: string,
    description: string,
    items: ItemKey[],
    ipmsTotalColor: string,
    calculatedColor: string,
  }[],
  labelColor: string, 
  items?: ItemKey[],
  ipmsTotalColor: string
  calculatedColor: string,
}

const COMPONENTS: Component[] = [
  {
    label: 'A',
    key: 'a',
    name: 'Columns, Walls and Notional Boundaries',
    labelColor: '#1f0938',
    nameColor: '#2f0e54',
    subComponents: [
      {
        label: 'A1',
        key: 'a1',
        name: 'Notional Boundary',
        description: 'The area between the Notional Boundary and the External Wall.',
        labelColor: '#3e1370',
        ipmsTotalColor: '#3d1370',
        calculatedColor: '#c4b8d4',
        items: ['total_area', 'limited_use_areas', 'total']
      },
      {
        label: 'A2',
        key: 'a2',
        name: 'External Structural elements',
        description: 'The External Wall area between the outside face and the IDF.',
        labelColor: '#4e188c',
        ipmsTotalColor: '#4e188c',
        calculatedColor: '#c4b8d4',
        items: ['total_area', 'total']
      },
      {
        label: 'A3',
        key: 'a3',
        name: 'Inter-surface adjustment',
        description: 'The Wall area between the IDF and the Finished Surface.',
        labelColor: '#6f22c7',
        ipmsTotalColor: '#6f22c8',
        calculatedColor: '#c4b8d4',
        items: ['total_area', 'total']
      },
      {
        label: 'A4',
        key: 'a4',
        name: 'Internal Structural Elements',
        description: 'Internal Walls, internal Columns and internal structures.',
        labelColor: '#924de1',
        ipmsTotalColor: '#914de0',
        calculatedColor: '#c4b8d4',
        items: ['total_area', 'total']
      },
      {
        label: 'A5',
        key: 'a5',
        labelColor: '#b688eb',
        ipmsTotalColor: undefined,
        calculatedColor: undefined,
        sections: [
          {
            label: 'A5',
            key: 'a5',
            name: 'Internal non-structural elements',
            description: 'Balustrades, if located within the measured floor area, full-height Internal Walls and similar non-structural elements other than those included in Component Area A1, A2 and A3. For Demising Walls this sub-component may be further subdivided. ',
            labelColor: '#dbc4f5',
            ipmsTotalColor: '#dbc4f5',
            calculatedColor: '#f4edfc',
            items: ['total_area', 'total']
          },
          {
            label: 'A5-1',
            key: 'a5_1',
            name: 'Demising walls',
            description: 'A Wall, other than an External Wall, between adjoining occupiers’ space or an occupier’s space and Standard Facilities.',
            labelColor: '#ede1fa',
            ipmsTotalColor: '#ede1fa',
            calculatedColor: '#f4edfc',
            items: ['total_area', 'total']
          }
        ]
      }
    ]
  },
  {
    label: 'B',
    key: 'b',
    name: 'Vertical Penetrations areas',
    labelColor: '#4d330d',
    nameColor: '#744d13',
    subComponents: [
      {
        label: 'B1',
        key: 'b1',
        name: 'Vertical circulation areas',
        description: 'Staircase openings, stairs, lift / elevator shafts and escalators.',
        labelColor: '#ba8637',
        ipmsTotalColor: '#ba8638',
        calculatedColor: '#eadac3',
        items: ['total_area', 'limited_use_areas', 'total']
      },
      {
        label: 'B2',
        key: 'b2',
        name: 'Vertical technical areas',
        description: 'Service shafts and ducts equal to or greater than 0.1 m2.',
        labelColor: '#d5a250',
        ipmsTotalColor: '#d5a250',
        calculatedColor: '#f2e3ca',
        items: ['total_area', 'limited_use_areas', 'total']
      }
    ]
  },
  {
    label: 'C',
    key: 'c',
    name: 'Technical areas',
    description: 'Mechanical and electrical plant rooms, lift / elevator motor rooms and maintenance rooms. ',
    labelColor: '#6d053b',
    nameColor: '#a40858',
    ipmsTotalColor: '#a40858',
    calculatedColor: '#e3b4cc',
    items: ['total_area', 'limited_use_areas', 'total']
  },
  {
    label: 'D',
    key: 'd',
    name: 'Sanitary areas',
    description: 'Toilet facilities, cleaners’/janitors’ cupboards, bath/shower rooms and changing rooms.',
    labelColor: '#052c0a',
    nameColor: '#06420e',
    ipmsTotalColor: '#06420e',
    calculatedColor: '#b4c6b6',
    items: ['total_area', 'limited_use_areas', 'total']
  },
  {
    label: 'E',
    key: 'e',
    name: 'Circulation areas',
    description: 'Circulation areas whether or not enclosed.',
    labelColor: '#014a81',
    nameColor: '#026fc2',
    ipmsTotalColor: '#036ec1',
    calculatedColor: '#b3d3ec',
    items: ['total_area', 'limited_use_areas', 'total']
  },
  {
    label: 'F',
    key: 'f',
    name: 'Primary areas',
    labelColor: '#254541',
    nameColor: '#448077',
  },
  {
    label: 'G',
    key: 'g',
    name: 'Secondary areas',
    labelColor: '#450c12',
    nameColor: '#68121b',
    subComponents: [
      {
        label: 'G1',
        key: 'g1',
        name: 'Amenity areas',
        description: 'Areas for the benefit of the primary purpose such as exclusive food court seating areas, exercise or child-minding facilities.',
        labelColor: '#8b1824',
        ipmsTotalColor: '#8b1824',
        calculatedColor: '#dbb9bd',
        items: ['total_area', 'limited_use_areas', 'total']
      },
      {
        label: 'G2',
        key: 'g2',
        name: 'Ancillary areas',
        description: 'Areas for the benefit of the primary purpose such as exclusive delivery areas, refuge areas and car parking that form part of the Building.',
        labelColor: '#ad1f2d',
        ipmsTotalColor: '#ad1f2d',
        calculatedColor: '#e6bbbf',
        items: ['total_area', 'limited_use_areas', 'total']
      }
    ]
  },
  {
    label: 'H',
    key: 'h',
    name: 'Other areas',
    labelColor: '#132b3f',
    nameColor: '#1c415e',
    subComponents: [
      {
        label: 'H1',
        key: 'h1',
        name: 'Other areas (general)',
        description: 'All other areas included in IPMS 1 but not otherwise included in Component Areas A–G and Sub-Component Areas H2 and H3 which may include External Floor Area(s) and Sheltered Area(s). ',
        labelColor: '#26567e',
        ipmsTotalColor: '#26567e',
        calculatedColor: '#beccd8',
        items: ['total_area', 'limited_use_areas', 'total']
      },
      {
        label: 'H2',
        key: 'h2',
        name: 'Other areas (construction)',
        description: 'Areas, such as the area between the Balustrade and the outside edge of the floor construction.',
        labelColor: '#2f6c9d',
        ipmsTotalColor: '#2f6b9d',
        calculatedColor: '#c0d2e1',
        items: ['total_area', 'limited_use_areas', 'total']
      },
      {
        label: 'H3',
        key: 'h3',
        name: 'Other areas (Standard facilities)',
        description: 'Landlord-provided Standard Facilities such as food court seating areas, exercise or child-minding facilities or other Standard Facilities such as delivery areas, refuge areas and car parking.',
        labelColor: '#438bc7',
        ipmsTotalColor: '#428ac8',
        calculatedColor: '#c6dcee',
        items: ['total_area', 'limited_use_areas', 'total']
      }
    ]
  }
]

export function getComponents(keys: string[], occupierNames: string[]) {
  const selectedComponentLabels = keys.map(key => getComponentLabelFromKey(key))
  const selectedSubComponentLabels = keys
    .filter(key => key.includes('_subcomponent'))
    .map(key => getSubComponentLabelFromKey(key))

  const selectedComponents = COMPONENTS
    .filter(component => selectedComponentLabels.includes(component.label.toLowerCase()))
    .map(component => ({
        ...component,
        subComponents: component.subComponents
          ? component.subComponents.filter(subComponent =>
            selectedSubComponentLabels.includes(subComponent.label.toLowerCase()))
          : undefined
      })
    )

  const convertedComponents = selectedComponents
    .map(component => {
      if (component.label === 'F') {
        return {
          ...component,
          colspan: 1,
          items: processItemsOfPrimaryAreas(occupierNames)
        }
      }
      return {
        ...component,
        colspan: component.items
          ? 3
          : 1,
        items: processItemsOfComponent(component)
      }
    })

  return convertedComponents
}

export function getConditionRatingComponents(keys: string[], occupierNames: string[]) {
  const selectedComponentLabels = keys.map(key => getComponentLabelFromKey(key))
  const selectedSubComponentLabels = keys
    .filter(key => key.includes('_subcomponent'))
    .map(key => getSubComponentLabelFromKey(key))

  const selectedComponents = COMPONENTS
    .filter(component => selectedComponentLabels.includes(component.label.toLowerCase()))
    .map(component => ({
        ...component,
        subComponents: component.subComponents
          ? component.subComponents.filter(subComponent =>
            selectedSubComponentLabels.includes(subComponent.label.toLowerCase()))
          : undefined
      })
    )
    .map(component => {
      if (component.label === 'F') {
        return occupierNames.map((name, idx) => ({
          label: `F${idx + 1}`,
          key: `f${idx + 1}`,
          name: name,
          description: null
        }))
      }
      if (!component.subComponents) {
        return {
          label: component.label,
          key: component.key,
          name: component.name,
          description: component.description
        }
      }
      return _.flatten(component.subComponents.map(subComponent => {
        if (!subComponent.sections) {
          return {
            label: subComponent.label,
            key: subComponent.key,
            name: subComponent.name,
            description: subComponent.description
          }
        }
        return _.flatten(subComponent.sections.map(section => ({
          label: section.label,
          key: section.key,
          name: section.name,
          description: section.description
        })))
      }))
    })

  return _.flatten(selectedComponents)
}

function getComponentLabelFromKey(key: string): string {
  return key.charAt(0)
}

function getSubComponentLabelFromKey(key: string): string {
  const parts = key.split('_')
  if (parts.length == 0) return ''
  return parts[0].toLowerCase()
}

function processItemsOfPrimaryAreas(occupierNames: string[]) {
  const oddSubComponentColor = '#6cb2a7';
  const evenSubComponentColor = '#a7d1ca';
  const oddIpmsTotalColor = '#6cb2a7';
  const eventIpmsTotalColor = '#a7d1ca';
  const oddCalculatedColor = '#d2e7e4';
  const eventCalculatedColor = '#e4f1ef';
  const items: ItemKey[] = ['total_area', 'limited_use_areas', 'total']
  return _.flatten(occupierNames.map((occupier, idx) => {
    return items.map((item, itemIdx) => {
      if (itemIdx === 0) {
        return processItem(
          item, 
          idx % 2 == 0 ? oddIpmsTotalColor : eventIpmsTotalColor,
          idx % 2 == 0 ? oddCalculatedColor : eventCalculatedColor,
          `f${idx + 1}`,
          {
            parentSubComponent: {
              label: `F${idx + 1} ${occupier ? occupier : ''}`,
              name: occupier,
              description: 'Areas used for primary purposes such as industrial, office, residential or retail. Primary areas that include sanitary areas and horizontal circulation areas, which form part of the occupant’s fit-out, may be sub-componentised, where not included in Component Areas D and E. ',
              colspan: 2,
              rowspan: 3,
              labelColor: idx % 2 == 0 ? oddSubComponentColor : evenSubComponentColor 
            }
          }
        )
      }
      return processItem(
        item,
        idx % 2 == 0 ? oddIpmsTotalColor : eventIpmsTotalColor,
        idx % 2 == 0 ? oddCalculatedColor : eventCalculatedColor,
        `f${idx + 1}`,
      )
    })
  }))
}

function processItemsOfComponent(component: Component): {
  key: ItemKey,
  label: string
}[] {
  if (component.items) {
    return component.items.map(item => processItem(
      item, 
      component.ipmsTotalColor, 
      component.calculatedColor,
      component.key
    ))
  }

  if (component.subComponents) {
    return _.flatten(
      component.subComponents.map(subComponent => {
        if (subComponent.label === 'A5') {
          return _.flatten(
            subComponent.sections.map((section, idx) => {
              if (idx === 0) {
                return section.items.map((item, idx) => {
                  if (idx === 0) {
                    return processItem(
                      item, 
                      section.ipmsTotalColor, 
                      section.calculatedColor, 
                      section.key,
                      {
                        parentSubComponent: {
                          label: subComponent.label,
                          name: subComponent.name,
                          description: subComponent.description,
                          colspan: 1,
                          rowspan: 4,
                          labelColor: subComponent.labelColor
                        },
                        parentSubComponentSection: {
                          label: section.label,
                          name: section.name,
                          description: section.description,
                          labelColor: section.labelColor,
                          colspan: 1,
                          rowspan: section.items.length
                        }
                    })
                  }
                  return processItem(item, section.ipmsTotalColor, section.calculatedColor, section.key)
                })
              }
              return section.items.map((item, idx) => {
                if (idx === 0) {
                  return processItem(item, section.ipmsTotalColor, section.calculatedColor, section.key, {
                    parentSubComponentSection: {
                      label: section.label,
                      name: section.name,
                      description: section.description,
                      labelColor: section.labelColor,
                      colspan: 1,
                      rowspan: section.items.length
                    }
                  })
                }
                return processItem(item, section.ipmsTotalColor, section.calculatedColor, section.key)
              })
            }
            )
          )
        }
        return subComponent.items.map((item, idx) => {
          if (idx === 0) {
            return processItem(item, subComponent.ipmsTotalColor, subComponent.calculatedColor, subComponent.key, {
              parentSubComponent: {
                label: subComponent.label,
                name: subComponent.name,
                description: subComponent.description,
                colspan: 2,
                rowspan: subComponent.items.length,
                labelColor: subComponent.labelColor
              }
            })
          }
          return processItem(item, subComponent.ipmsTotalColor, subComponent.calculatedColor, subComponent.key)
        })
      })
    )
  }

  return []
}

function processItem(
  key: ItemKey, 
  ipsmTotalColor: string, 
  calculatedColor: string, 
  component: string,
  layout?: {
    parentSubComponent?: {
      label: string,
      name: string,
      description: string,
      colspan: number,
      rowspan: number,
      labelColor: string
    },
    parentSubComponentSection?: {
      label: string,
      name: string,
      description: string,
      labelColor: string,
      colspan: number,
      rowspan: number
    }
}) {
  if (key === 'total_area') {
    return {
      key: key,
      component: component,
      label: 'Total area',
      type: 'input',
      ...layout
    }
  }
  if (key === 'limited_use_areas') {
    return {
      key: key,
      component: component,
      label: 'Limited use areas',
      type: 'input',
    }
  }
  return {
    key: key,
    component: component,
    label: 'IPMS Total',
    type: 'text',
    labelColor: ipsmTotalColor,
    calculatedColor: calculatedColor
  }
}
