import { FloorColumn } from './types'

export function createFloorColumn(floor: number, occupiers: string[]): FloorColumn {
  const base: FloorColumn = {
    number: floor,
    a1: {
      total_area: null,
      limited_use_areas: null
    },
    a2: {
      total_area: null
    },
    a3: {
      total_area: null
    },
    a4: {
      total_area: null
    },
    a5: {
      total_area: null
    },
    a5_1: {
      total_area: null
    },
    b1: {
      total_area: null,
      limited_use_areas: null
    },
    b2: {
      total_area: null,
      limited_use_areas: null
    },
    c: {
      total_area: null,
      limited_use_areas: null
    },
    d: {
      total_area: null,
      limited_use_areas: null
    },
    e: {
      total_area: null,
      limited_use_areas: null
    },
    g1: {
      total_area: null,
      limited_use_areas: null
    },
    g2: {
      total_area: null,
      limited_use_areas: null
    },
    h1: {
      total_area: null,
      limited_use_areas: null
    },
    h2: {
      total_area: null,
      limited_use_areas: null
    },
    h3: {
      total_area: null,
      limited_use_areas: null
    }
  }
  const occupierComponents = occupiers.reduce((acc, occupier, idx) => {
    return {
      ...acc,
      [`f${idx + 1}`]: {
        total_area: null,
        limited_use_areas: null
      }
    }
  }, {})
  return {
    ...base,
    ...occupierComponents
  }
}

export function calculateIPMSTotals(floors: FloorColumn[]): FloorColumn[] {
  return floors.map(floor => {
    const calculated = Object.entries(floor)
      .reduce((acc, [key, value]) => {
        if (typeof value !== 'object') {
          return acc
        }
        const total = Object.values(value).reduce((sum: number, item: number) => sum + item, 0)
        return {
          ...acc,
          [key]: {
            ...value,
            total
          }
        }
      }, {} as FloorColumn)
    return {
      number: floor.number,
      ...calculated
    }
  })
}

export function calculateTotals(floors: FloorColumn[], occupiers: string[]) {
  const calculated = calculateIPMSTotals(floors)
  const componentAndKeysList = [
    {
      component: 'a1',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'a2',
      keys: ['total_area', 'total']
    },
    {
      component: 'a3',
      keys: ['total_area', 'total']
    },
    {
      component: 'a4',
      keys: ['total_area', 'total']
    },
    {
      component: 'a5',
      keys: ['total_area', 'total']
    },
    {
      component: 'a5_1',
      keys: ['total_area', 'total']
    },
    {
      component: 'b1',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'b2',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'c',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    ...occupiers.map((_, idx) => ({
      component: `f${idx + 1}`,
      keys: ['total_area', 'limited_use_areas', 'total']
    })),
    {
      component: 'd',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'e',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'g1',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'g2',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'h1',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'h2',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
    {
      component: 'h3',
      keys: ['total_area', 'limited_use_areas', 'total']
    },
  ]
  const result = componentAndKeysList.reduce((acc, {component, keys}) => {
    const _itemObject = keys.reduce((itemObject, key) => {
      const total = calculated
        .map(item => item[component][key])
        .reduce((sum: number, value: number) => sum + value, 0)

      return {
        ...itemObject,
        [key]: total
      }
    }, {})
    return {
      ...acc,
      [component]: _itemObject
    }
  }, {})
  return result
}

export function calculateTotalRow(floors: FloorColumn[]) {
  const calculated = calculateIPMSTotals(floors)  
  const result = calculated.map(floor => {
    const calculatedFloor = Object.values(floor)
      .reduce((acc, item) => {
        if (typeof item !== 'object') return acc
        return {
          'total_area': acc['total_area'] + Number(item['total_area']),
          'limited_use_areas': acc['limited_use_areas'] + (item['limited_use_areas'] ? Number(item['limited_use_areas']) : 0),
          'total': acc['total'] + Number(item['total'])
        }
      }, {
        'total_area': 0,
        'limited_use_areas': 0,
        'total': 0
      })

    return {
      number: floor.number,
      ...calculatedFloor
    }
  })
  result.sort((a, b) => a.number - b.number)

  const total = result.reduce((acc, item) => {
    return {
      'total_area': item['total_area'] + acc['total_area'],
      'limited_use_areas': item['limited_use_areas'] + acc['limited_use_areas'],
      'total': item['total'] + acc['total'],
    }
  }, {
    'total_area': 0,
    'limited_use_areas': 0,
    'total': 0
  })
  return {
    floors: result,
    total
  }
}