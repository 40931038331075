import { Component } from './types'
export const GIAComponents: Component[] = [
  {
    label: 'A',
    key: 'a',
    componentColor: '#e3342f',
    itemColor: '#f2938e',
    inputColor: '#fbcecc',
    totalColor: '#f2938e',
    items: [
      {
        key: 'a',
        name: 'Internal load-bearing walls & partitions',
        description: 'Areas occupied by load bearing internal walls, columns and partitions (2.1)'
      },
      {
        key: 'b',
        name: 'Columns, chimney breasts, stairwells & others',
        description: 'Columns, piers, chimney breasts, stairwells, lift-wells, other internal projections, vertical ducts, and the like (2.2)'
      },
      {
        key: 'c',
        name: 'Atria & entrance halls',
        description: 'Atria and entrance halls, with clear height above, measured at base level only (2.3)'
      }
    ]
  },
  {
    label: 'B',
    key: 'b',
    componentColor: '#f6993f',
    itemColor: '#fbc79a',
    inputColor: '#fee6d2',
    totalColor: '#fbc79a',
    items: [
      {
        name: 'Internal balconies',
        description: 'Internal open-sided balconies, walkways, and the like (2.4)'
      }
    ]
  },
  {
    label: 'C',
    key: 'c',
    componentColor: '#ffed4a',
    itemColor: '#fff5a2',
    inputColor: '#fffbd6',
    totalColor: '#fff5a2',
    items: [
      {
        key: 'a',
        name: 'Vertical circulation area',
        description: 'Structural, raked or stepped floors are to be treated as a level floor measured horizontally (2.5)'
      },
      {
        key: 'b',
        name: 'Floors underneath vertical circulation area',
        description: 'Horizontal floors, which permanent access, below structural, raked or stepped floors (2.6)'
      },
      {
        key: 'c',
        name: 'Circulation areas',
        description: 'Corridors of a permanent essential nature (e.g. fire corridors, smoke lobbies) (2.7)'
      }
    ]
  },
  {
    label: 'D',
    key: 'd',
    componentColor: '#38c172',
    itemColor: '#9cddb3',
    inputColor: '#d2f0dd',
    totalColor: '#9cddb3',
    items: [
      {
        name: 'Mezzanine areas',
        description: 'Mezzanine floor areas intended with permanent access (2.8)'
      }
    ]
  },
  {
    label: 'E',
    key: 'e',
    componentColor: '#4dc0b5',
    itemColor: '#a2ddd5',
    inputColor: '#d6f0ed',
    totalColor: '#a2ddd5',
    items: [
      {
        key: 'a',
        name: 'Technical services',
        description: 'Lift rooms, plant rooms, fuel stores, tank rooms which are housed in a covered structure of a permanent nature, whether or not above the main roof level (2.9)'
      },
      {
        key: 'b',
        name: 'Service accommodation',
        description: 'Service accommodation such as toilets, toilet lobbies, bathrooms, showers, changing rooms, cleaners’ rooms, and the like (2.10)'
      }
    ]
  },
  {
    label: 'F',
    key: 'f',
    componentColor: '#3490dc',
    itemColor: '#95c2ed',
    inputColor: '#cfe4f7',
    totalColor: '#95c2ed',
    items: [
      {
        name: 'Projection rooms',
        description: 'Projection rooms (2.11)'
      }
    ]
  },
  {
    label: 'G',
    key: 'g',
    componentColor: '#6574cd',
    itemColor: '#abb3e4',
    inputColor: '#d8dcf2',
    totalColor: '#abb3e4',
    items: [
      {
        name: 'Loading bays',
        description: 'Loading bays (2.13)'
      }
    ]
  },
  {
    label: 'H',
    key: 'h',
    componentColor: '#9561e2',
    itemColor: '#c5a8ef',
    inputColor: '#e4d8f7',
    totalColor: '#c5a8ef',
    items: [
      {
        name: 'Room area',
        description: 'Room areas including the ones occupied by non load bearing internal walls and partitions (2.1.1) and (30.4)'
      }
    ]
  },
  {
    label: 'I',
    key: 'i',
    componentColor: '#f66d9b',
    itemColor: '#fcb0c9',
    inputColor: '#ffdce6',
    totalColor: '#fcb0c9',
    items: [
      {
        key: 'a',
        name: 'Voids over stairwells and lift shafts',
        description: 'Voids over stairwells and lift shafts on upper floors (2.12)'
      },
      {
        key: 'b',
        name: 'Areas with a headroom of less than 1.5m',
        description: 'Areas with a headroom of less than 1.5m (2.14)'
      },
      {
        key: 'c',
        name: 'Underground space',
        description: 'Pavement vaults (2.15)',
      },
      {
        key: 'd',
        name: 'Garages',
        description: 'Garages & parkings (2.16)'
      },
      {
        key: 'e',
        name: 'Conservatories',
        description: 'Conservatories (2.17)'
      }
    ]
  }
]