<table class="table">
  <thead>
    <th colspan="2">Component Area</th>
    <th colspan="2">Sub-components</th>
    <th>Items</th>
    <th *ngFor="let floor of floors$|async">
      <ng-container *ngIf="floor.schemeId == 1">
        <span *ngIf="floor.number < 0">
          Lower Ground ({{floor.number}})
        </span>
        <span *ngIf="floor.number == 0">
          Ground
        </span>
        <span *ngIf="floor.number > 0">
          Floor {{floor.number}}
        </span>
      </ng-container>
      <ng-container *ngIf="floor.schemeId == 2">
        <span *ngIf="floor.number < 0">
          Lower Ground ({{floor.number}})
        </span>
        <span *ngIf="floor.number == 1">
          Floor 1 (Ground)
        </span>
        <span *ngIf="floor.number > 1">
          Floor {{floor.number}}
        </span>
      </ng-container>
    </th>
    <th>Total</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let component of components$|async">
      <tr *ngFor="let item of component.items; let i = index">
        <td *ngIf="i == 0" 
          class="cell cell-label"
          [attr.rowspan]="component.items.length"
          [style.background-color]="component.labelColor">
          {{ component.label }}
        </td>
        <td 
          *ngIf="i == 0"
          class="cell cell-name"
          [attr.rowspan]="component.items.length"
          [attr.colspan]="component.colspan"
          [style.background-color]="component.nameColor">
          <div>
            {{ component.name }}
            <mat-icon *ngIf="component.description" container="body" [ngbPopover]="component.description" [popoverTitle]="component.name">
              help
            </mat-icon>
          </div>
        </td>
        <td 
          *ngIf="item.parentSubComponent" 
          class="cell cell-label"
          [attr.rowspan]="item.parentSubComponent.rowspan" 
          [attr.colspan]="item.parentSubComponent.colspan" 
          [style.background-color]="item.parentSubComponent.labelColor">
          <div>
            {{ item.parentSubComponent.label }}
            <mat-icon *ngIf="item.parentSubComponent.description" container="body" [ngbPopover]="item.parentSubComponent.description" [popoverTitle]="item.parentSubComponent.name">
              help
            </mat-icon>
          </div>
        </td>
        <td *ngIf="item.parentSubComponentSection"
          class="cell cell-label"
          [attr.rowspan]="item.parentSubComponentSection.rowspan"
          [attr.colspan]="item.parentSubComponentSection.colspan"
          [style.background-color]="item.parentSubComponentSection.labelColor">
          <div>
            {{ item.parentSubComponentSection.label }}
            <mat-icon *ngIf="item.parentSubComponentSection.description" container="body" [ngbPopover]="item.parentSubComponentSection.description" [popoverTitle]="item.parentSubComponentSection.name">
              help
            </mat-icon>
          </div>
        </td>
        <td
          class="cell cell-item"
          [ngClass]="{
            'cell-ipms-total': item.key == 'total',
          }"
          [ngStyle]="{
            'background-color': item.key == 'total' ? item.labelColor : undefined
          }"
        >
          {{ item.label }}
        </td>
        <td 
          *ngFor="let floor of floors$|async"
          class="cell"
          [ngClass]="{
            'cell-calculated cell-dashed': item.key == 'total',
            'cell-last': item.key === 'total'
          }"
          [ngStyle]="{
            'background-color': item.key == 'total' ? item.calculatedColor : undefined
          }"
        >
          <ng-container *ngIf="item.type == 'input'">
            <input 
              type="text" 
              [value]="floor[item.component][item.key]"
              (focusout)="onFocusOut($event, floor.number, item.component, item.key)" />
          </ng-container>
          <ng-container *ngIf="item.type == 'text'">
            {{ floor[item.component]['total'] }}
          </ng-container>
        </td>
        <td
          class="cell cell-total"
          [ngClass]="{
            'cell-ipms-total cell-dashed cell-last': item.key == 'total',
            'cell-calculated': item.key != 'total'
          }"
          [ngStyle]="{
            'background-color': item.key == 'total' ? item.labelColor : undefined
          }"
        >
          <ng-container *ngIf="totals$|async as totals">
            {{ totals[item.component][item.key] }}
          </ng-container>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="totalRow$|async as totalRow">
      <tr>
        <td colspan="4" rowspan="3" class="cell cell-label" [style.background-color]="'#141414'">
          TOTAL {{ totalRow.name }}
        </td>
        <td class="cell cell-item">
          Total area
        </td>
        <td *ngFor="let floor of totalRow.floors" class="cell">
          {{ floor.total_area }}
        </td>
        <td class="cell cell-calculated cell-total">
          {{ totalRow.total.total_area }}
        </td>
      </tr>
      <tr>
        <td class="cell cell-item">Limited use areas</td>
        <td *ngFor="let floor of totalRow.floors" class="cell">
          {{ floor.limited_use_areas }}
        </td>
        <td class="cell cell-calculated cell-total">
          {{ totalRow.total.limited_use_areas }}
        </td>
      </tr>
      <tr>
        <td class="cell cell-item cell-ipms-total cell-last" [style.background-color]="'#141315'">IPMS Total</td>
        <td *ngFor="let floor of totalRow.floors" class="cell cell-calculated cell-dashed cell-last" [style.background-color]="'#b8b8b8'">
          {{ floor.total }}
        </td>
        <td class="cell cell-ipms-total cell-dashed cell-last cell-total" [style.background-color]="'#141315'">
          {{ totalRow.total.total }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>