<table class="table">
  <thead>
    <th>
      Components
    </th>
    <th colspan="2">
      Items
    </th>
    <th *ngFor="let floor of floors$|async">
      <ng-container *ngIf="floor.schemeId == 1">
        <span *ngIf="floor.number < 0">
          Lower Ground ({{floor.number}})
        </span>
        <span *ngIf="floor.number == 0">
          Ground
        </span>
        <span *ngIf="floor.number > 0">
          Floor {{floor.number}}
        </span>
      </ng-container>
      <ng-container *ngIf="floor.schemeId == 2">
        <span *ngIf="floor.number < 0">
          Lower Ground ({{floor.number}})
        </span>
        <span *ngIf="floor.number == 1">
          Floor 1 (Ground)
        </span>
        <span *ngIf="floor.number > 1">
          Floor {{floor.number}}
        </span>
      </ng-container>
    </th>
    <th>
      Total
    </th>
  </thead>

  <tbody>
    <ng-container *ngFor="let component of components$|async">
      <tr *ngFor="let item of component.items; let i = index">
        <td *ngIf="i == 0" 
          class="cell cell-component"
          [attr.rowspan]="component.items.length"
          [style.background-color]="component.bgColor">
          {{ component.label }}
        </td>
        <td *ngIf="item.label"
          class="cell cell-item-label"
          [ngClass]="{
            'cell-last': (i + 1) == component.items.length
          }"
          [style.background-color]="item.bgColor">
          ({{item.label}})
        </td>
        <td 
          class="cell cell-item-name"
          [ngClass]="{
            'cell-last': (i + 1) == component.items.length
          }"
          [attr.colspan]="item.label ? 1 : 2"
          [style.background-color]="item.bgColor">
          <div>
            <span>
              {{ item.name }}
            </span>
            <mat-icon inline container="body" [ngbPopover]="item.description">
              help
            </mat-icon>
          </div>
        </td>
        <td *ngFor="let floor of floors$|async"
          [ngClass]="{
            'cell-last': (i + 1) == component.items.length
          }"
          [style.background-color]="item.inputColor">
          <input
            type="text"
            [value]="floor[item.key] ?? null"
            (focusout)="onFocusOut($event, floor.number, item.key)" />
        </td>
        <td class="cell cell-total cell-calculated"
          [ngClass]="{
            'cell-last': (i + 1) == component.items.length
          }"
          [style.background-color]="item.totalColor">
          <ng-container *ngIf="totals$|async as totals">
            {{ totals[item.key] }}
          </ng-container>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="totalRow$|async as totalRow">
      <tr>
        <td class="cell cell-component cell-last" style="background-color: #929292">
          {{ totalRow.name }}
        </td>
        <td colspan="2" class="cell cell-item-name cell-last" style="background-color: #c2c2c2">
          Total
        </td>
        <td *ngFor="let floor of floors$|async" class="cell cell-calculated cell-last" style="background-color: #e3e3e3">
          {{ totalRow[floor.number] }}
        </td>
        <td class="cell cell-total cell-last cell-calculated" style="background-color: #c2c2c2">
          {{ totalRow.total }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>