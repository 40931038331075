import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SubdomainService } from "src/app/core/_base/subdomain.service";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { IPMS } from "src/app/core/v2/constants";
import { environment } from "src/environments/environment";
import { getComponents, getConditionRatingComponents } from "./ipms-component.util";
import { FloorColumn } from "./types";

const IPMSConfig = {
  [IPMS.IPMS1]: [
    'a1_subcomponent', 
    'a2_subcomponent', 
    'a3_subcomponent', 
    'a4_subcomponent', 
    'a5_subcomponent',
    'a5_1_subcomponent',
    'b1_subcomponent',
    'b2_subcomponent',
    'c_component',
    'd_component',
    'e_component',
    'f_component',
    'g1_subcomponent',
    'g2_subcomponent',
    'h1_subcomponent',
    'h2_subcomponent',
    'h3_subcomponent',
  ],
  [IPMS.IPMS2]: [
    'a1_subcomponent', 
    'a3_subcomponent', 
    'a4_subcomponent', 
    'a5_subcomponent',
    'a5_1_subcomponent',
    'b1_subcomponent',
    'b2_subcomponent',
    'c_component',
    'd_component',
    'e_component',
    'f_component',
    'g1_subcomponent',
    'g2_subcomponent',
    'h1_subcomponent',
    'h3_subcomponent',
  ],
  [IPMS.IPMS3_1]: [
    'a1_subcomponent', 
    'a2_subcomponent', 
    'a3_subcomponent', 
    'a4_subcomponent', 
    'a5_subcomponent',
    'a5_1_subcomponent',
    'b1_subcomponent',
    'b2_subcomponent',
    'e_component',
    'f_component',
    'g1_subcomponent',
    'g2_subcomponent',
    'h1_subcomponent',
  ],
  [IPMS.IPMS3_2]: [
    'a1_subcomponent', 
    'a3_subcomponent', 
    'a4_subcomponent', 
    'a5_subcomponent',
    'a5_1_subcomponent',
    'b1_subcomponent',
    'b2_subcomponent',
    'e_component',
    'f_component',
    'g1_subcomponent',
    'g2_subcomponent',
    'h1_subcomponent',
  ],
  [IPMS.IPMS4_1]: [
    'a1_subcomponent', 
    'a4_subcomponent',
    'a5_subcomponent',
    'a5_1_subcomponent',
    'b1_subcomponent',
    'b2_subcomponent',
    'c_component',
    'd_component',
    'e_component',
    'f_component',
    'g1_subcomponent',
    'g2_subcomponent',
    'h1_subcomponent',
    'h3_subcomponent',
  ],
  [IPMS.IPMS4_2]: [
    'a1_subcomponent', 
    'b1_subcomponent',
    'b2_subcomponent',
    'c_component',
    'd_component',
    'e_component',
    'f_component',
    'g1_subcomponent',
    'g2_subcomponent',
    'h1_subcomponent',
    'h3_subcomponent',
  ]
}

@Injectable({
  providedIn: 'root'
})
export class IPMSSizeService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subdomainService: SubdomainService
  ) {}

  public fetchIPMSSizeByAssetClass(assetClassId: number, unitAreaMeasurementId: number) {
    const params = new HttpParams().append('uamid', unitAreaMeasurementId.toString())
    return this.http.get<{
      data: FloorColumn[]
    }>(
      `${environment.baseApiUrl}api/v2/${this.subdomainService.subDomain}/target-properties/${assetClassId}/ipms-size`, {
      headers: this.authService.getAuthHeaders(),
      params
    })
  }

  public getComponents(ipmsId: number, occupierNames: string[]) {
    const keys: string[] = IPMSConfig[ipmsId]
    if (!keys) return [];

    const components = getComponents(keys, occupierNames)
    return components
  }

  public getConditionRatingComponents(ipmsId: number, occupierNames: string[]) {
    const keys: string[] = IPMSConfig[ipmsId]
    if (!keys) return [];
    return getConditionRatingComponents(keys, occupierNames)
  }
}