import { NgModule } from "@angular/core";
import { CodeOfMeasurementTableComponent } from "./code-of-measurement-table/code-of-measurement-table.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NgbPopoverModule
  ],
  declarations: [
    CodeOfMeasurementTableComponent
  ],
  exports: [
    CodeOfMeasurementTableComponent
  ]
})
export class CodeOfMeasurementModule {}