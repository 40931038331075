import {BaseModel} from '../../_base/crud';
import {AssetClassDefaultAssumptionModel} from './asset-class-default-assumption.model';
import {AssetClassSpecialAssumptionModel} from './asset-class-special-assumption.model';
import {AssetClassDepartureModel} from './asset-class-departure.model';
import {ValuationModel} from './valuation.model';
import {AssetClassDetailModel} from './asset-class-detail.model';
import {AssetClassSourceExternalReferenceModel} from './asset-class-source-external-reference.model';
import { LocalVpgaModel } from './local-vpga.model';

export class AssetClassModel extends BaseModel {
    id: number;
    ac_id: number;
    details: AssetClassDetailModel;
    investigation_status: boolean;

    name: string;
    prev_name: string;
    surface: number;
    is_complete: number;
    toe_id: number;
    toe_name: string;

    top_property_type_id: number;
    top_property_type_name: string;

    type_id: number;
    type_name: string;

    state: number;

    asset_classification_id: number;

    premise_of_value_id: number;
    premise_of_value_name: string;
    tenure_id: number;
    tenure_name: string;
    floor_numbering_scheme_id: number;
    floors_valuated: string;
    included_in_valuation_id: number;
    furniture_is: string;
    base_of_value_id: number;
    percentage: number;
    discount: number;
    discount_descr: string;

    country_id: number;
    city_id: number;
    zip_code: string;
    address: string;
    latitude: number;
    longitude: number;
    timezone_offset: string;

    property_type_id: number;
    property_type_name: string;

    currency_exchange_rate: string;
    country_currency: string;
    cashflow_currency: string;
    reporting_currency: string;

    expected_limitations_restrictions: number;
    limitations_and_restrictions: string;

    restrict_investigation: number;
    restrict_investigation_descr: string;

    unit_of_area_measurement_id: number;
    unit_of_area_measurement_name: string;
    measurement_methodology_id: number;
    measurement_methodology_name: string;
    standard_measurement_id: number;
    standard_measurement_name: string;
    new_standard_measurement_name: string;
    purpose_measurement_id: number;
    purpose_measurement_name: string;

    included_all_equipment: number;
    included_all_equipment_descr: string;
    included_furniture: number;
    included_furniture_descr: string;
    natural_accident_consideration: number;
    natural_accident_consideration_descr: string;
    material_accident_consideration: number;
    material_accident_consideration_descr: string;
    diligence_check: number;
    diligence_check_descr: string;
    type_of_inspection: number;
    instructed_to_measure: number;
    intended_lender: number;
    specific_req_on_intended_lender: number;
    specific_req_on_intended_lender_descr: string;
    level_consideration: string;
    vpgas: LocalVpgaModel[];

    selected_default_assumptions: AssetClassDefaultAssumptionModel[];
    selected_special_assumptions: AssetClassSpecialAssumptionModel[];
    selected_departures: AssetClassDepartureModel[];
    valuations: ValuationModel[];
    all_valuations: ValuationModel[];
    external_references: AssetClassSourceExternalReferenceModel[];

    reporting_id: number;

    inspection_date: string;
    draft_date: string;
    valuation_date: string;
    selected: boolean = false;

    current_point: number;
    max_point: number

    // Custom Target Property fields
    custom_asset_class_name: string;
    custom_property_type: string;
    custom_tenure: string;
    custom_floor_valuated: string;

    // New IPMS
    ipms_name: string
    ipms_description: string

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    clear() {
        this.id = null;

        this.name = '';
        this.surface = 0;
        this.is_complete = null;
        this.toe_id = null;
        this.toe_name = '';

        this.top_property_type_id = null;
        this.type_id = null;
        this.type_name = '';

        this.premise_of_value_id = null;
        this.premise_of_value_name = '';
        this.tenure_id = null;
        this.tenure_name = '';
        this.floor_numbering_scheme_id = null;
        this.floors_valuated = '';
        this.included_in_valuation_id = null;
        this.furniture_is = '';
        this.base_of_value_id = null;
        this.percentage = 100;
        this.discount = null;
        this.discount_descr = '';
        this.vpgas = [];

        this.country_id = null;
        this.city_id = null;
        this.zip_code = '';
        this.address = '';
        this.latitude = undefined;
        this.longitude = undefined;
        this.timezone_offset = null;

        this.property_type_id = null;
        this.property_type_name = '';

        this.currency_exchange_rate = null;
        this.country_currency = '';
        this.cashflow_currency = '';
        this.reporting_currency = '';

        this.expected_limitations_restrictions = 0;
        this.limitations_and_restrictions = '';

        this.restrict_investigation = 0;
        this.restrict_investigation_descr = '';

        this.unit_of_area_measurement_id = null;
        this.unit_of_area_measurement_name = '';

        this.included_all_equipment = 1;
        this.included_all_equipment_descr = '';
        this.included_furniture = 0;
        this.included_furniture_descr = '';
        this.natural_accident_consideration = 0;
        this.natural_accident_consideration_descr = '';
        this.material_accident_consideration = 0;
        this.material_accident_consideration_descr = '';
        this.diligence_check = 0;
        this.diligence_check_descr = '';
        this.type_of_inspection = 1;
        this.instructed_to_measure = 0;
        this.intended_lender = 1;
        this.specific_req_on_intended_lender = 0;
        this.specific_req_on_intended_lender_descr = '';
        this.level_consideration = '';
        this.new_standard_measurement_name = null;

        this.selected_default_assumptions = [];
        this.selected_special_assumptions = [];
        this.selected_departures = [];
        this.valuations = [];
        this.external_references = []

        this.custom_tenure = '';
        this.custom_asset_class_name = '';
        this.custom_floor_valuated = '';
        this.custom_property_type = '';

        this.reporting_id = null;

        this.inspection_date = '';
        this.draft_date = '';
        this.valuation_date = '';
        this.selected = false;

        this.ipms_name = null
        this.ipms_description = null;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = null;
    }
}
