import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, finalize, map, skipUntil, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LogisticParticipant, LogisticsNote, LogisticsService } from './logistics.service';
import { TabHeader } from '../../shared_components/tab-header/tab-header.component';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { awConst } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { EditNoteComponent } from '../valuation-process-edit/_sub/background-tab/edit-note/edit-note.component';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { User, currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeFieldComponent } from '../../shared_components/inspection-details/time-field/time-field.component';
import { LogiscticsParticipantEditComponent } from './components/logisctics-participant-edit/logisctics-participant-edit.component';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { Location } from '@angular/common';
import { DatepickerTzInputComponent } from '../../mad-forms/datepicker-tz-input/datepicker-tz-input.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

@Component({
  selector: 'kt-logistics-page',
  templateUrl: './logistics-page.component.html',
  styleUrls: ['./logistics-page.component.scss']
})
export class LogisticsPageComponent implements OnInit {
  assetClassId: number = undefined;
  private _loading$ = new BehaviorSubject(true)
  loading$ = this._loading$.asObservable()

  /**
   * Tabs
   */
  tabHeaders: TabHeader[] = [
    {
      label: '1 - Background', 
      disabled: of(false), 
      activeButton: {
        label: 'PDF',
        onClick: () => {
          this.exportLogistics()
        }
      }
    },
    {label: '2 - Input', disabled: of(false)}
  ]
  selectedTab = 0
  private _selectedTabChange$ =  new BehaviorSubject(0)
  selectedTabChange$ = this._selectedTabChange$.asObservable()
  onTabChange(index: number) {
    this.selectedTab = index
  }

  hasFormErrors = false
  error: any = {
    msg: ' Missing Fields in total',
    fields: []
  }
  form = new FormGroup({
    id: new FormControl(undefined),
    inspection_date: new FormControl(''),
    time_of_inspection: new FormControl(''),
    duration_of_inspection: new FormControl(''),
    has_limitations: new FormControl(false),
    limitations: new FormArray(
      [new FormControl('')]
    ),
    inspection_notes: new FormArray(
      [new FormControl('')]
    ),
    information_date: new FormControl(''),
    source_of_information: new FormControl('')
  })
  get limitationsControls() {
    const formArray = this.form.controls.limitations
    return formArray
  }
  get inspectionNotesControls() {
    return this.form.controls.inspection_notes
  }

  backgroundInfo$ = this.activatedRoute.params.pipe(
    tap(params => this.assetClassId = Number(params.asset_class_id)),
    switchMap(params => {
      return this.service.edit(Number(params.asset_class_id)).pipe(
        finalize(() => this._loading$.next(false))
      )
    }),
  )
  notes$ = this.service.notes$
  timezoneOffset$ = this.service.timezoneOffset$
  inspectionDetails$ = this.service.inspectionDetail$.pipe(
    filter(inspectionDetail => inspectionDetail != null),
    tap(inspectionDetails => {
      if (inspectionDetails.id === undefined) {
        return;
      }
      this.form.patchValue({
        id: inspectionDetails.id,
        inspection_date: inspectionDetails.inspection_date,
        time_of_inspection: inspectionDetails.time_of_inspection,
        duration_of_inspection: readableDuration(inspectionDetails.duration_of_inspection),
        has_limitations: inspectionDetails.has_limitations,
        information_date: inspectionDetails.information_date,
        source_of_information: inspectionDetails.source_of_information
      })
      if (inspectionDetails.limitations.length > 0) {
        const fArray = new FormArray([]);
        inspectionDetails.limitations.forEach(_ => fArray.push(new FormControl('')));
        this.form.controls.limitations = fArray
        this.form.patchValue({limitations: inspectionDetails.limitations})
      }
      if (inspectionDetails.inspection_notes.length > 0) {
        const fArray = new FormArray([]);
        inspectionDetails.inspection_notes.forEach(_ => fArray.push(new FormControl('')))
        this.form.controls.inspection_notes = fArray;
        this.form.patchValue({inspection_notes: inspectionDetails.inspection_notes})
      }
    }),
    tap(inspectionDetails => {
      if (inspectionDetails.type_of_inspection_id != 1) {
        this.form.controls.inspection_date.addValidators([Validators.required])
        this.form.controls.time_of_inspection.addValidators([Validators.required])
        this.form.controls.duration_of_inspection.addValidators([Validators.required])
        this.form.controls.information_date.addValidators(null)
        this.form.controls.source_of_information.addValidators(null)
      } else {
        this.form.controls.information_date.addValidators([Validators.required])
        this.form.controls.source_of_information.addValidators([Validators.required])
        this.form.controls.inspection_date.addValidators(null)
        this.form.controls.time_of_inspection.addValidators(null)
        this.form.controls.duration_of_inspection.addValidators(null)
      }
      this.form.controls.inspection_date.updateValueAndValidity()
      this.form.controls.source_of_information.updateValueAndValidity()
      this.form.controls.information_date.updateValueAndValidity()
      this.form.controls.time_of_inspection.updateValueAndValidity()
      this.form.controls.duration_of_inspection.updateValueAndValidity()
    })
  )
  participants$ = this.service.participants$;
  currentUser: User = null;


  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: LogisticsService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private typesUtilsService: TypesUtilsService,
    private location: Location,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.store.pipe(
      takeUntil(this._onDestroy$),
      select(currentUser)
    ).subscribe(res => {
      if (res) {
        this.currentUser = res
      }
    })
  }

  onSubmit(complete: boolean) {
    let errorFields = []
    this.hasFormErrors = false
    if (complete && this.form.invalid) {
      Object.keys(this.form.controls).forEach(controlName => {
        this.form.controls[controlName].markAsTouched()
        if (this.form.controls[controlName].invalid) {
          errorFields = [...errorFields, controlName]
        }
      })
      this.hasFormErrors = true
      this.error.fields = errorFields
      return;
    }

    const value = {
      id: this.form.controls.id.value,
      is_complete: complete,
      notes: this.service.notes,
      participants: this.service.participants,
      inspection_date: this.form.controls.inspection_date.value ? this.typesUtilsService.getDateStringFromDate(new Date(this.form.controls.inspection_date.value)) : null,
      time_of_inspection: this.form.controls.time_of_inspection.value,
      duration_of_inspection: durationFromReadable(this.form.controls.duration_of_inspection.value),
      has_limitations: this.form.controls.has_limitations.value,
      limitations: this.form.controls.limitations.value.filter(v => v != null && v != ""),
      inspection_notes: this.form.controls.inspection_notes.value.filter(v => v != null && v != ""),
      tp_id: this.assetClassId ,
      information_date: this.form.controls.information_date.value ? this.typesUtilsService.getDateStringFromDate(new Date(this.form.controls.information_date.value)) : null,
      source_of_information: this.form.controls.source_of_information.value
    } 
    this.service.upsert(value).subscribe(res => {
      this.location.back();
    })
  }

  showUserInfo(popover: NgbPopover, user: {role: string, qualification: string, email: string, agency: string, user_pic: string | null}) {
    popover.popoverClass = 'my-popover'
    if (popover.isOpen()) {
      popover.close()
    } else {
      popover.open({
        role: user.role,
        qualication: user.qualification,
        email: user.email,
        agency: user.agency,
        user_pic: user.user_pic && user.user_pic.length > 0 
          ? (environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + user.user_pic) 
          : './assets/media/users/default.jpg'
      })
    }
  }

  onImageError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  /**
   * Notes
   */
  onAddNote() {
    const dialogRef = this.dialog.open(EditNoteComponent, {
      width: '50vw',
      data: {
        isEdit: false,
        user: this.currentUser
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      this.service.addNote({input: res.input, user: this.currentUser})
    })
  }
  onStatusChange(event: MatCheckboxChange, noteIndex: number) {
    this.service.changeNoteStatus(event.checked, noteIndex)
  }
  onEditNote(note: LogisticsNote, noteIndex: number) {
    const dialogRef = this.dialog.open(EditNoteComponent, {
      width: '50vw',
      data: {
        isEdit: true,
        item: note,
        user: this.currentUser
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      this.service.editNote(res.input, noteIndex)
    })
  }
  onDeleteNote(noteIndex: number) {
    this.service.deleteNote(noteIndex)
  }

  /**
   * Dates
   */
  onClearDate(ctlr: AbstractControl) {
    ctlr.setValue(null)
  }
  onOpenTimeField(event) {
    const durationOfInspectionCtrl = this.form.controls.duration_of_inspection
    const dialog = this.dialog.open(TimeFieldComponent, {
      data: {
        trigger: new ElementRef(event.currentTarget),
        duration: durationFromReadable(durationOfInspectionCtrl.value) 
      },
      width: '18.5em',
      backdropClass: 'map-backdrop'
    })
    dialog.afterClosed().subscribe(val => {
      if (!val) {
        return
      }
      durationOfInspectionCtrl.setValue(readableDuration(val))
    })
  }

  /**
   * Participants
   */
  onAddParticipant() {
    const dialogRef = this.dialog.open(LogiscticsParticipantEditComponent, {
      data: {
        participant: null
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }
      this.service.addParticipant(res.participant)
    })
  }
  onEditParticipant(participant: LogisticParticipant, participantIndex: number) {
    const dialogRef = this.dialog.open(LogiscticsParticipantEditComponent, {
      data: {
        participant
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }
      this.service.editParticipant(res.participant, participantIndex)
    })
  }
  onDeleteParticipant(participantIndex: number) {
    this.service.deleteParticipant(participantIndex)
  }
  onParticipantDescriptionChange(event, participant: LogisticParticipant, participantIndex: number) {
    this.service.editParticipant({
      ...participant,
      descr: event.target.value
    }, participantIndex)
  }
  onParticipantIncludedChange(event: MatCheckboxChange, participant: LogisticParticipant, participantIndex: number) {
    this.service.editParticipant({
      ...participant,
      included: event.checked
    }, participantIndex)
  }

  /**
   * Limitations and inspection notes
   */
  onAddLimitations() {
    this.form.controls.limitations.push(new FormControl(''))
  }
  onLimitationsDelete(index: number) {
    this.form.controls.limitations.removeAt(index)
  }
  onAddInspectionNote() {
    this.form.controls.inspection_notes.push(new FormControl(''))
  }
  onInspectionNoteDelete(index: number) {
    this.form.controls.inspection_notes.removeAt(index)
  }

  clearInformationDate(datePicker: DatepickerTzInputComponent) {
    datePicker.clearDate()
  }
  exportLogistics() {
    this.service.export(this.assetClassId)
      .subscribe(res => {
        if (res.type == 4) {
          let blob = new Blob([res.body], { type: 'application/pdf'});
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll('"', '');
          let url = window.URL.createObjectURL(blob);
          let anchor = document.createElement("a");
          anchor.download = filename;
          anchor.href = url;
          anchor.click();
        }
      },
      err => {
        alert("Problem while downloading the file.");
      }
    );
  }

  errorTabMap() {
    return this.typesUtilsService.getLogisticTabErrorMap(this.error.fields, this.tabHeaders)
  }
  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormErrors = obj.hasFormErrors
  }
}

function durationFromReadable(readable: string): string {
    const splits = readable.split(' ');
    if (splits.length === 4) {
        return `${splits[0]}:${splits[2]}`;
    }
    if (splits.length === 2) {
        return `00:${splits[0]}`;
    }
    return null;
}

function readableDuration(duration: string): string {
    if (duration === null) {
        return ''
    }
    const splits = duration.split(':');
    if (splits.length != 2) {
        return ''
    }

    const hours = Number(splits[0]);
    const minutes = Number(splits[1]);
    if (hours == 0) {
        return `${minutes} minute(s)`;
    }
    return `${hours} hour(s) ${minutes} minute(s)`
}