import { Component } from './types'
export const NIAComponents: Component[] = [
  {
    label: 'A',
    key: 'a',
    componentColor: '#e3342f',
    itemColor: '#f2938e',
    inputColor: '#fbcecc',
    totalColor: '#f2938e',
    items: [
      {
        key: 'a',
        name: 'Atria',
        description: 'Atria with clear height above, measured at base level only in exclusive use (3.1)'
      },
      {
        key: 'b',
        name: 'Entrance halls',
        description: 'Entrance halls in exclusive use (3.2)'
      },
      {
        key: 'c',
        name: 'Lift lobbies and fire corridors',
        description: 'Notional lift lobbies and notional fire corridors in exclusive use (3.3)'
      }
    ]
  },
  {
    label: 'B',
    key: 'b',
    componentColor: '#f6993f',
    itemColor: '#fbc79a',
    totalColor: '#fee6d2',
    inputColor: '#fbc79a',
    items: [
      {
        name: 'Amenities',
        description: 'Kitchens & cafeterias, day care facilities, fitness areas and prayer rooms (3.4)'
      }
    ]
  },
  {
    label: 'C',
    key: 'c',
    componentColor: '#ffed4a',
    itemColor: '#fff5a2',
    totalColor: '#fffbd6',
    inputColor: '#fff5a2',
    items: [
      {
        name: 'Techincal services',
        description: 'Built-in units, cupboards, and the like occupying usable areas (3.5)'
      }
    ]
  },
  {
    label: 'D',
    key: 'd',
    componentColor: '#38c172',
    itemColor: '#9cddb3',
    totalColor: '#d2f0dd',
    inputColor: '#9cddb3',
    items: [
      {
        name: 'Vertical circulation area',
        description: 'Ramps, sloping areas and steps within usable areas (3.6)'
      }
    ]
  },
  {
    label: 'E',
    key: 'e',
    componentColor: '#4dc0b5',
    itemColor: '#a2ddd5',
    totalColor: '#d6f0ed',
    inputColor: '#a2ddd5',
    items: [
      {
        name: 'Room area',
        description: 'Room area in sole occupancy, including industrial or commercial purpose. including the areas occupied by ventilation/ heating grilles, skirting and perimeter trunking and non-structural walls subdividing accommodation in sole occupancy (3.7; 3.8 and 3.9)'
      }
    ]
  },
  {
    label: 'F',
    key: 'f',
    componentColor: '#3490dc',
    itemColor: '#95c2ed',
    totalColor: '#cfe4f7',
    inputColor: '#95c2ed',
    items: [
      {
        name: 'Other',
        description: 'Pavement vaults (3.10'
      }
    ]
  }
]