import { CommonModule } from '@angular/common'
import { NgModule } from "@angular/core";
import { ConditionRatingComponent } from "./condition-rating.component";
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    NgbPopoverModule
  ],
  declarations: [ConditionRatingComponent],
  exports: [ConditionRatingComponent]
})
export class ConditionRatingModule {}