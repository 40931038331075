<form *ngIf="parentFormGroup" [formGroup]="parentFormGroup" [ngClass]="{'readonly': readonly}">
	<div class="row mb-2">
		<div class="col-lg-12">
			<h5 class="my-3 text-mad text-uppercase">VPGA 2 - Valuations for secured lending</h5>
			<hr class="active">
		</div>
	</div> 
	<div class="row mb-10">
		<div class="col-lg-12">
			<h6 class="text-mad mb-5">General Comments:</h6>
		</div>
		<div class="col-lg-12" formGroupName="generalComments">
			<ng-container *ngFor="let question of generalCommentsQuestion; let i = index">
				<div class="row mb-5" [ngClass]="{'gray': i%2}">
					<div class="col-lg-12">
						<strong>{{question.index}} - {{question.name}}</strong>
					</div>
					<div class="col-lg-12 mt-4 mb-2" *ngIf="question.formControlType == 'radio-button'">
						<mat-radio-group [formControlName]="question.formControlName">
							<mat-radio-button [value]="'0'">No</mat-radio-button>
							<mat-radio-button [value]="'1'">Yes</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-lg-12" *ngIf="castAbstractController(castAbstractController(parentFormGroup).controls.generalComments).controls[question.formControlName].value == '1' && question.formControlNameDescr.length > 0">
						<mat-form-field class="mat-form-field-fluid">
							<!-- <input matInput [placeholder]="'Please indicate here'"
								[formControlName]="question.formControlNameDescr" /> -->
							<textarea matInput 
								[placeholder]="question.formControlNameDescr == 'price_been_disclosed_descr'
													? 'Please indicate the extent to which that information has been accepted as evidence of value'  
													: 'Please indicate here'" [formControlName]="question.formControlNameDescr"></textarea>
							<mat-error *ngIf="castAbstractController(castAbstractController(parentFormGroup).controls.generalComments).controls[question.formControlName].value == '1' && castAbstractController(castAbstractController(parentFormGroup).controls.generalComments).controls[question.formControlNameDescr].hasError('required')">
								Required
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-lg-12" *ngIf="question.formControlType == 'text-input'">
						<mat-form-field class="mat-form-field-fluid">
							<textarea matInput [placeholder]="'Please indicate here'" [formControlName]="question.formControlName"></textarea>
							<!-- <input matInput [formControlName]="question.formControlName" placeholder="Please indicate here"> -->
						</mat-form-field>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="row mb-20">
		<div class="col-lg-12">
			<h6 class="text-mad mb-5">Property Specific disclosures:</h6>
		</div>
		<div class="col-lg-12" formGroupName="property_specific_disclosure">
			<div class="row mb-5">
				<div class="col-lg-12">
					<strong>
						1 - Please select the type of property valued: 
					</strong>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-lg-12">
					<mat-radio-group formControlName="type_of_property">
						<mat-radio-button [value]="0">Property that is, or will be, held as an investment.</mat-radio-button>
						<mat-radio-button [value]="1">Property that is, or is intended to be, the subject of development or refurbishment.</mat-radio-button>
						<mat-radio-button [value]="2">Property that is fully equipped as a trading entity and valued with regard to trading potential.</mat-radio-button>
					</mat-radio-group>
				</div>
			</div>
			<div class="row mb-5" *ngIf="typeOfPropertyControl.value == 2">
				<div class="col-lg-12">
					<div class="mb-4">
						<strong>
							2 - The closure of the business could have a significant impact on the market value. The valuer should therefore report on this impact, 
							either individually or as a combination of one or more of the following special assumptions:
						</strong>
					</div>
					<div class="py-1" *ngFor="let closureOfBusiness of closureOfBusinesses; let i = index">
						<mat-checkbox
							[checked]="checkOfSelected(closureOfBusiness)"
							(change)="updateCoBCheckedOptions(i, closureOfBusiness, $event)"
						>
							<span>
								{{closureOfBusiness.name}}
							</span>
						</mat-checkbox>
					</div>
					<mat-error class="tag-custom-error mt-2"
						*ngIf="selectedCoBAssumptions.length == 0">
						At least one <strong>required</strong>
					</mat-error>
					<mat-form-field class="mat-form-field-fluid">
						<!-- <input matInput [placeholder]="'Please specify'" formControlName="market_value_impact_descr" /> -->
						<textarea matInput [placeholder]="'Please specify'" formControlName="market_value_impact_descr"></textarea>
						<mat-error>
							Required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="row" *ngIf="typeOfPropertyControl.value == 0">
				<div class="col-lg-12">
					<ng-container *ngFor="let question of investmentQuestion; let i = index">
						<div class="row mb-5" [ngClass]="{'gray': i%2 == 0}">
							<div class="col-lg-12">
								<strong>{{question.index}} - {{question.name}}</strong>
							</div>
							<div class="col-lg-12 mt-4 mb-2" *ngIf="question.formControlType == 'radio-button'">
								<mat-radio-group [formControlName]="question.formControlName">
									<mat-radio-button [value]="'0'">No</mat-radio-button>
									<mat-radio-button [value]="'1'">Yes</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="col-lg-12" *ngIf="castAbstractController(castAbstractController(parentFormGroup).controls.property_specific_disclosure).controls[question.formControlName].value == '1' && question.formControlNameDescr.length > 0">
								<mat-form-field class="mat-form-field-fluid">
									<!-- <input matInput [placeholder]="'Please indicate here'"
										[formControlName]="question.formControlNameDescr" /> -->
									<textarea matInput [placeholder]="'Please indicate here'" [formControlName]="question.formControlNameDescr"></textarea>
									<mat-error *ngIf="castAbstractController(castAbstractController(parentFormGroup).controls.property_specific_disclosure).controls[question.formControlName].value == '1' && castAbstractController(castAbstractController(parentFormGroup).controls.property_specific_disclosure).controls[question.formControlNameDescr].hasError('required')">
										Required
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-lg-12" *ngIf="question.formControlType == 'text-input'">
								<mat-form-field class="mat-form-field-fluid">
									<textarea matInput [placeholder]="'Please indicate here'" [formControlName]="question.formControlName"></textarea>
									<!-- <input matInput [formControlName]="question.formControlName" placeholder="Please indicate here"> -->
								</mat-form-field>
							</div>
							<div class="col-lg-12" *ngIf="question.hasDocument">
								<span class="btn btn-primary"
									color="primary"
									mat-raised-button (click)="addFiles(question)">
									Uploaded Files {{question.index == 2 
										? summaryOfOccupationalLeasesFiles.length > 0 
											? '(' + summaryOfOccupationalLeasesFiles.length + ')'
											: ''
										: currentRentalIncomeFiles.length > 0 
											? '(' + currentRentalIncomeFiles.length + ')'
											: ''}}
								</span>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="row" *ngIf="typeOfPropertyControl.value == 1">
				<div class="col-lg-12">
					<ng-container *ngFor="let question of developmentQuestion; let i = index">
						<div class="row mb-5" [ngClass]="{'gray': i%2 == 0}">
							<div class="col-lg-12">
								<strong>{{question.index}} - {{question.name}}</strong>
							</div>
							<div class="col-lg-12">
								<mat-form-field class="mat-form-field-fluid">
									<textarea matInput [placeholder]="'Please indicate here'" [formControlName]="question.formControlName"></textarea>
									<!-- <input matInput [formControlName]="question.formControlName" placeholder="Please indicate here"> -->
								</mat-form-field>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>
