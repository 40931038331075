import {StandardMeasurement} from '../_models/standard-measurement.model';

// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// CRUD
import {QueryResultsModel, HttpExtenstionsModel} from '../../_base/crud';
// State
import {StandardMeasurementsState} from '../_reducers/standard-measurement.reducers';
import * as fromStandardMeasurement from '../_reducers/standard-measurement.reducers';
import {each} from 'lodash';
import { STANDARD_MEASUREMENT } from '../../v2/constants';
import { IPMS_IDS } from 'src/app/views/pages/mad_shared/ipms-size-table/ids';

export const selectStandardMeasurementsState = createFeatureSelector<StandardMeasurementsState>('standardMeasurements');

export const selectStandardMeasurementById = (standardMeasurementId: number) => createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => standardMeasurementsState.entities[standardMeasurementId]
);

export const selectAllStandardMeasurements = createSelector(
    selectStandardMeasurementsState,
    fromStandardMeasurement.selectAll
);


export const selectStandardMeasurementsForAc = (acId: number) => createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => {
        const items: StandardMeasurement[] = [];
        each(standardMeasurementsState.entities, element => {
            if (element.asset_class_type_id == acId) {
                items.push(element);
            }
            if (IPMS_IDS.includes(element.id)) {
                items.push(element)
            }
        });
        return items;
    }
);


export const selectAllStandardMeasurementsIds = createSelector(
    selectStandardMeasurementsState,
    fromStandardMeasurement.selectIds
);

export const allStandardMeasurementsLoaded = createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => standardMeasurementsState.isAllStandardMeasurementsLoaded
);


export const selectStandardMeasurementsPageLoading = createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => standardMeasurementsState.listLoading
);

export const selectStandardMeasurementsActionLoading = createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => standardMeasurementsState.actionsLoading
);

export const selectLastCreatedStandardMeasurementId = createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => standardMeasurementsState.lastCreatedStandardMeasurementId
);

export const selectStandardMeasurementsShowInitWaitingMessage = createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => standardMeasurementsState.showInitWaitingMessage
);

export const selectTrashedStandardMeasurementCount = createSelector(
    selectStandardMeasurementsState,
    (standardMeasurementsState) => standardMeasurementsState.totalTrashed
);

export const selectAdminTrashedStandardMeasurementCount = createSelector(
    selectStandardMeasurementsState,
    (standardMeasurementsState) => standardMeasurementsState.totalAdminTrashed
);


export const selectStandardMeasurementsQueryResult = createSelector(
    selectStandardMeasurementsState,
    standardMeasurementsState => {
        const items: StandardMeasurement[] = [];
        each(standardMeasurementsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: StandardMeasurement[] = httpExtension.sortArray(items, standardMeasurementsState.lastQuery.sortField, standardMeasurementsState.lastQuery.sortOrder);

        return new QueryResultsModel(result, standardMeasurementsState.totalCount, '', standardMeasurementsState.totalTrashed);
    }
);
