<kt-portlet (window:beforeunload)="saveAssignmentState()">
    <kt-portlet-header
            [sticky]="true"
            *ngIf="oldToe && assignment"
            [title]="getComponentTitle()"
            [class]="'kt-portlet-head--lg'"
            [tooltipTitle]="'TOOLTIP.TOE.FORM'+(oldToe.id ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.TOE.FORM'+(oldToe.id ? '_EDIT':'')+'.DESCRIPTION' | translate"
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a (click)="back()"
               href="javascript:;"
               class="btn mr-2" mat-raised-button [matTooltip]="backTitle">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{'TOE.BUTTON.CLOSE' | translate}}</span>
            </a>
            <span *ngIf="toe && toe.status < 1 && !(clientInformed|async)">
                <a href="javascript:;"
                class="btn btn-success mr-2"
                color="warn"
                style="background-color: orange !important;"
                (click)="saveDraft()"
                mat-raised-button
                [matTooltip]="'TOE.TOOLTIP.SAVE_DRAFT' | translate">
                    <span class="kt-hidden-mobile">{{'TOE.BUTTON.SAVE_DRAFT' | translate}}</span>
                </a>


                <a href="javascript:;"
                class="btn btn-success mr-2"
                color="submit"
                (click)="onSubmit(true)"
                *ngIf="canValidate"
                mat-raised-button [matTooltip]="'TOE.TOOLTIP.SAVE_VALIDATE' | translate">
                    <span class="kt-hidden-mobile">{{'TOE.BUTTON.SAVE_VALIDATE' | translate}}</span>
                </a>
            </span>

            <span *ngIf="toe && toe.status > 0">
            <a href="javascript:;"
               class="btn btn-secondary mr-2"
               mat-raised-button
               color="secondary"
               (click)="goDashboard()"
               [matTooltip]="'TOE.TOOLTIP.REACH_DASHBOARD' | translate">
                <span class="kt-hidden-mobile">{{'TOE.BUTTON.REACH_DASHBOARD' | translate}}</span>
            </a>
            </span>
        </ng-container>
    </kt-portlet-header>
    <kt-portlet-header *ngIf="oldToe && isTemplate"
        [sticky]="true"
        [title]="getComponentTitle()">
        <ng-container ktPortletTools>
            <a (click)="back()"
               href="javascript:;"
               class="btn mr-2" mat-raised-button [matTooltip]="backTitle">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{'TOE.BUTTON.CLOSE' | translate}}</span>
            </a>
            <a href="javascript:;"
            class="btn btn-success mr-2"
            color="submit"
            *ngIf="!readonly"
            (click)="onSubmit(true)"
            mat-raised-button [matTooltip]="'Save'">
                <span class="kt-hidden-mobile">Save</span>
            </a>
            <!-- <a href="javascript:;"
            class="btn btn-success mr-2"
            color="submit"
            (click)="onSubmit(false)"
            mat-raised-button [matTooltip]="'Save'">
                <span class="kt-hidden-mobile">Save false</span>
            </a> -->
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <kt-tab-header 
            [headerLabels]="tabHeaders" 
            (tabChange)="onTabChange($event)"
            [selectedTabChange]="selectedtabchange$">
        </kt-tab-header>
        <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" *ngIf="toe" (selectedTabChange)="onTabChanged($event);">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>1&nbsp;-&nbsp;{{'TOE.TAB.TITLE1' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <!--begin::Form-->
                    <div [ngClass]="{
                        'readonly': readonly || toe.status > 0
                    }">
                    <div class="row" *ngIf="hasNotCertifiedValuer$|async">
                        <div class="col-md-12">
                            <div class="warning-info">
                                <mat-icon>warning</mat-icon>
                                <p>To save ToE, you must have a user with CERTIFIED VALUER qualification</p>
                            </div>
                        </div>
                    </div>
                        <form [formGroup]="toeForm1" class="kt-form kt-form--group-seperator-dashed">

                            <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
                                {{errorMessage}}
                            </kt-alert>
                            <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile px-0">
                                <h5 class="my-3 text-mad text-uppercase">GENERAL INFORMATION</h5>
                                <hr class="active">
                            </div>
                            <div class="kt-form-section kt-form-section--first" *ngIf="isTemplate">
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input matInput type="text" placeholder="Template Name" formControlName="template_name">
                                        <mat-error>Template Name <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput type="text" placeholder="Template Description" formControlName="template_description"></textarea>
                                        <mat-error>Template Description <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Agency"
                                                    formControlName="template_agency">
                                            <mat-option [value]="-1">Any</mat-option>
                                            <mat-option *ngFor="let agency of agencies"
                                                        [value]="agency.id">{{agency.name}}
                                            </mat-option>
                                        </mat-select>

                                        <mat-icon
                                                matSuffix
                                                class="cursor-pointer icon-gray"
                                                container="body"
                                                [popoverTitle]="'TOOLTIP.TEMPLATE.TOE_AGENCY.TITLE' | translate"
                                                [ngbPopover]="'TOOLTIP.TEMPLATE.TOE_AGENCY.DESCRIPTION' | translate"
                                                (click)="$event.stopPropagation();">help
                                        </mat-icon>

                                        <mat-error>Agency
                                            <strong>required</strong>
                                        </mat-error>

                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="kt-form-section kt-form-section--first">

                                <div class="mt-2 kt-margin-bottom-20-mobile" *ngIf="!isTemplate">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input matInput placeholder="ToE ID" formControlName="name"/>
                                        <mat-error>ToE ID
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Purpose of Valuation"
                                                    (selectionChange)="selectPurpose($event)"
                                                    formControlName="purpose_of_valuation_id">
                                            <mat-option *ngFor="let purposeOfValuation of purposeOfValuations"
                                                        [value]="purposeOfValuation.id">{{purposeOfValuation.name}}
                                            </mat-option>
                                        </mat-select>

                                        <mat-icon
                                                matSuffix
                                                class="cursor-pointer icon-gray"
                                                container="body"
                                                [popoverTitle]="'TOOLTIP.TOE.PURPOSE.TITLE' | translate"
                                                [ngbPopover]="'TOOLTIP.TOE.PURPOSE.DESCRIPTION' | translate"
                                                (click)="$event.stopPropagation();">help
                                        </mat-icon>

                                        <mat-error>Purpose of Valuation
                                            <strong>required</strong>
                                        </mat-error>

                                    </mat-form-field>
                                </div>
                                <div class="kt-margin-bottom-10-mobile" *ngIf="toeForm1.controls.purpose_of_valuation_id.value && toeForm1.controls.purpose_of_valuation_id.value == CONSTANTS.pov_none">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput rows="2" matInput placeholder="Reason why the purpose of valuation was not disclosed by the client"
                                                  formControlName="purpose_of_valuation_reason"></textarea>
                                        <mat-error>
                                            Reason why the purpose of valuation was not disclosed by the client is
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-label>
                                            Please indicate here the status of the valuer
                                            <mat-icon
                                                    class="cursor-pointer icon-gray"
                                                    container="body"
                                                    [popoverTitle]="'TOOLTIP.TOE.STATUS_OF_VALUER.TITLE' | translate"
                                                    [ngbPopover]="'TOOLTIP.TOE.STATUS_OF_VALUER.DESCRIPTION' | translate"
                                                    (click)="$event.stopPropagation();">help
                                            </mat-icon>
                                        </mat-label>
                                    </div>
                                    <div class="mb-4">
                                        <mat-radio-group class="tp-radio-group" formControlName="status_of_valuer">
                                            <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                                *ngFor="let status of statuOfValuer" [value]="status.value">
                                                {{status.label}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="kt-margin-bottom-20-mobile mt-4 mb-2">
                                        <div class="mb-4">
                                            <mat-label>
                                                Do the limitations on liability for this ToE (agreed upon with the client) differ from the generic limitations on liability held by the
                                                valuer?
                                            </mat-label>
                                            <mat-icon
                                                    class="cursor-pointer icon-gray"
                                                    container="body"
                                                    [popoverTitle]="'TOOLTIP.TOE.LIMITATION.TITLE' | translate"
                                                    [ngbPopover]="'TOOLTIP.TOE.LIMITATION.DESCRIPTION' | translate"
                                                    (click)="$event.stopPropagation();">help
                                            </mat-icon>
                                        </div>
                                        <div class="mb-4">
                                            <mat-radio-group class="tp-radio-group" formControlName="limitations_on_liability">
                                                <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                                                  *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                                    {{decision.label}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div class="kt-margin-bottom-20-mobile mb-4" *ngIf="toeForm1.controls.limitations_on_liability.value == '1'">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <textarea matInput placeholder="Please specify" rows="2" formControlName="limitations_on_liability_descr"></textarea>
                                            <mat-error>
                                                Please specify
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="mb-8 kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Governing Laws of These Terms of Engagement" formControlName="law_country_id">
                                                <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
                                            </mat-select>
                                            <mat-error>Governing Laws of These Terms of Engagement
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile px-0">
                                    <h5 class="my-3 text-mad text-uppercase">standards used</h5>
                                    <hr class="active" />
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-label>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bold">1 - Core Standard(s)</span>
                                                <mat-icon
                                                    inline
                                                    class="cursor-pointer icon-gray ml-2"
                                                    container="body"
                                                    ngbPopover="Primary standard establishing the core framework for the entire system management process"
                                                    (click)="$event.stopPropagation();">
                                                    help
                                                </mat-icon>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <div class="mb-4">
                                        <mat-radio-group class="tp-radio-group" formControlName="core_standard_id">
                                            <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                                *ngFor="let option of (coreStandards$|async)" [value]="option.value">
                                                {{option.label}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error
                                            *ngIf="coreStandardControl && coreStandardControl.invalid && (coreStandardControl.dirty || coreStandardControl.touched)"
                                            class="tag-custom-error tag-custom-margin-top-10">
                                            Core Standard <strong>required</strong>
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-label>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bold">2 - National Supplement(s)</span>
                                                <mat-icon
                                                    inline
                                                    class="cursor-pointer icon-gray ml-2"
                                                    container="body"
                                                    ngbPopover="Additional national standard that augments the primary standard, reinforcing the comprehensive system management process"
                                                    (click)="$event.stopPropagation();">
                                                    help
                                                </mat-icon>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <div class="mb-4">
                                        <span class="text-danger">No national supplement(s) available yet.</span>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-label>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bold">3 - Custom Supplement(s)</span>
                                                <mat-icon
                                                    inline
                                                    class="cursor-pointer icon-gray ml-2"
                                                    container="body"
                                                    ngbPopover="Recorded custom standards included for informational purposes only, without impact on the system management process execution"
                                                    (click)="$event.stopPropagation();">
                                                    help
                                                </mat-icon>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <div class="mb-4 row">
                                        <div class="col-md-12 kt-margin-bottom-20-mobile">
                                            <div class="row" *ngIf="valuationStandards.length > 0">
                                                <div *ngFor="let valuationStandard of valuationStandards; let i = index"
                                                    class="col-lg-4 kt-margin-bottom-20-mobile pt-2">
                                                    <mat-checkbox
                                                            [checked]="setSelectedValuationStandard(valuationStandard)"
                                                            (change)="updateCheckedOptions(valuationStandard, $event)"
                                                    >
                                                        {{valuationStandard.name}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="valuationStandards.length == 0">
                                                <div class="col-12">
                                                    <p class="text-danger">You do not have any custom supplements in your database. To add one, either you or your administrator must navigate to the linked table section within the admin panel. Selecting a custom supplement is purely informational and does not impact the process in any way.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile mb-8">
                                    <div class="mb-4">
                                        <mat-label>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bold">4 - System of Measurement</span>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <div class="mb-4 row">
                                        <div class="col-md-4 kt-margin-bottom-10-mobile mt-2">
                                            <mat-form-field class="mat-form-field-fluid">
                                                <mat-select placeholder="System of Measurement" formControlName="unit_of_measurement_id">
                                                    <mat-option *ngFor="let item of units" [value]="item.id">{{item.name}}</mat-option>
                                                </mat-select>
                                                <mat-error>System of Measurement
                                                    <strong>required</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile px-0">
                                    <h5 class="my-3 text-mad text-uppercase">esg reporting</h5>
                                    <hr class="active" />
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <p>
                                        Requirements in relation to the consideration of significant environmental, social and governance (ESG) factors: subject to the availability of the data,
                                        and within the limitations of its capabilities as defined within the terms of engagement, the valuer will collect, review and comment on any of the
                                        following matters: 
                                    </p>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-checkbox formControlName="esg_reporting_energy_rating">
                                            Energy rating and performance & greenhouse gas emissions
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-checkbox formControlName="esg_reporting_renewable_energy_production">
                                            Renewable energy production (onsite)
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-checkbox formControlName="esg_reporting_labels_and_certificates">
                                            Labels and certificates
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-checkbox formControlName="esg_reporting_exposed_risks">
                                            Emission pathway analysis and physical climate risks
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile">
                                    <div class="mb-4">
                                        <mat-checkbox formControlName="esg_reporting_other">
                                            Other
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile" *ngIf="toeForm1.controls.esg_reporting_other.value">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput placeholder="Please specify" rows="2" formControlName="esg_reporting_other_description" ></textarea>
                                        <mat-error>
                                            <strong>Required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile px-0">
                                    <h5 class="my-3 text-mad text-uppercase">VALUATION FUNDAMENTALS</h5>
                                    <hr class="active">
                                </div>
                                <div class="mt-2 kt-margin-bottom-20-mobile mb-8">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Basis of Value"
                                                    formControlName="base_of_value_id">
                                            <mat-option *ngFor="let baseOfValue of baseOfValues"
                                                        [value]="baseOfValue.id">
                                                {{baseOfValue.name}} {{baseOfValue.source ? ' - ' + baseOfValue.source.toUpperCase() : ''}}
                                            </mat-option>
                                        </mat-select>

                                        <mat-icon
                                                matSuffix
                                                class="cursor-pointer icon-gray"
                                                container="body"
                                                [popoverTitle]="'TOOLTIP.TOE.BASIS.TITLE' | translate"
                                                [ngbPopover]="'TOOLTIP.TOE.BASIS.DESCRIPTION' | translate"
                                                (click)="$event.stopPropagation();">help
                                        </mat-icon>

                                        <mat-error>Basis of Value
                                            <strong>required</strong>
                                        </mat-error>

                                    </mat-form-field>
                                </div> -->
                                <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile mt-2  px-0">
                                    <h5 class="my-3 text-mad text-uppercase">STATUTORY DISCLOSURES</h5>
                                    <hr class="active">
                                </div>
                                <div class="kt-margin-bottom-20-mobile mt-4 mb-2">
                                    <div class="mb-4">
                                        <mat-label>
                                            Are the valuation results to be disclosed to any third party or in the public domain?
                                        </mat-label>
                                    </div>
                                    <div class="mb-4">
                                        <mat-radio-group class="tp-radio-group" formControlName="restrictions_on_use">
                                            <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                                              *ngFor="let decision of decisionsExtra" [value]="decision.value" [disabled]="toeForm1.controls.purpose_of_valuation_id.value && toeForm1.controls.purpose_of_valuation_id.value == CONSTANTS.pov_none">
                                                {{decision.label}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="mb-4" *ngIf="toeForm1.controls.restrictions_on_use.value == 1">
                                        <div class="row">
                                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                                <mat-form-field class="mat-form-field-fluid">
                                                    <input matInput 
                                                        #field
                                                        placeholder="Please indicate here the other intended users"
                                                        formControlName="restrictions_on_use_intended_users" />

                                                    <mat-error *ngIf="toeForm1.controls.restrictions_on_use_intended_users.hasError('required')">It is
                                                        <strong>required</strong>
                                                    </mat-error>
                                                    <mat-icon
                                                            matSuffix
                                                            class="cursor-pointer icon-gray"
                                                            container="body"
                                                            [popoverTitle]="'TOOLTIP.TOE.OTHER_INTENDED_USERS.TITLE' | translate"
                                                            [ngbPopover]="'TOOLTIP.TOE.OTHER_INTENDED_USERS.DESCRIPTION' | translate"
                                                            (click)="$event.stopPropagation();">help
                                                    </mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <div lass="col-lg-12 kt-margin-bottom-20-mobile">
                                                <div class="d-inline-block mt-2 mr-3 ml-4" 
                                                    style="font-size: small;">
                                                    <b>Mitigation strategy: </b> 
                                                        Where reliance may be placed on a valuation by a third party who or which is identifiable from the outset,
                                                        the disclosures in accordance with the RICS Red Book 2022 - PS 2 paragraph 5.2 must be made promptly to that party
                                                        before the valuation is undertaken
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-margin-bottom-20-mobile mt-4 mb-2" *ngIf="toeForm1.controls.restrictions_on_use.value == '1'">
                                    <div class="mb-4">
                                        <mat-label>
                                            Are any responsibilities and liabilities to third parties accepted by the valuer?

                                            <mat-icon
                                                    class="cursor-pointer icon-gray"
                                                    container="body"
                                                    [popoverTitle]="'TOOLTIP.TOE.THIRD_PARTY_DISCLOSURE.TITLE' | translate"
                                                    [ngbPopover]="'TOOLTIP.TOE.THIRD_PARTY_DISCLOSURE.DESCRIPTION' | translate"
                                                    (click)="$event.stopPropagation();">help
                                            </mat-icon>
                                        </mat-label>
                                    </div>
                                    <div class="mb-4">
                                        <mat-radio-group class="tp-radio-group" formControlName="responsibilities_liabilities">
                                            <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                                              *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                                {{decision.label}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="kt-margin-bottom-20-mobile mb-4" *ngIf="toeForm1.controls.responsibilities_liabilities.value == '1' && toeForm1.controls.restrictions_on_use.value == '1'">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput placeholder="Please specify" rows="2" formControlName="responsibilities_liabilities_descr"></textarea>
                                        <mat-error>
                                            Please specify
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="kt-margin-bottom-20-mobile mt-4 mb-2">
                                    <div class="mb-4">
                                        <mat-label>
                                            Will the valuer rely upon a source of data which differs from the agency's generic sources?
                                        </mat-label>
                                        <mat-icon
                                                class="cursor-pointer icon-gray"
                                                container="body"
                                                [popoverTitle]="'TOOLTIP.TOE.SOURCE_DATA.TITLE' | translate"
                                                [ngbPopover]="'TOOLTIP.TOE.SOURCE_DATA.DESCRIPTION' | translate"
                                                (click)="$event.stopPropagation();">help
                                        </mat-icon>
                                    </div>
                                    <div class="mb-4">
                                        <mat-radio-group class="tp-radio-group" formControlName="generic_one">
                                            <mat-radio-button class="tp-radio-button kt-margin-r-20"
                                                              *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                                {{decision.label}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="kt-margin-bottom-20-mobile mb-4" *ngIf="toeForm1.controls.generic_one.value == '1'">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <textarea matInput placeholder="Please specify" rows="2" formControlName="generic_one_descr"></textarea>
                                        <mat-error>
                                            Please specify
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <br>
                            <div class="form-group kt-form-group row mb-2">
                                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                    <h5 class="my-3 text-mad text-uppercase">Client information</h5>
                                    <hr class="active">
                                    <div class="kt-margin-bottom-20-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select
                                                    formControlName="client_id"
                                                    (selectionChange)="selectClient($event)"
                                                    placeholder="Client"
                                                    [compareWith]="matSelectCompareWith"
                                                    #singleSelect>
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="filterCtrl"
                                                                           placeholderLabel="Search"
                                                                           noEntriesFoundLabel="No Client">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option>
                                                    <div (click)="addClient()" class="add-entry">
                                                        <i class="ki ki-plus"></i> New client
                                                    </div>
                                                </mat-option>
                                                <mat-option *ngFor="let _data of filteredSelectionModels | async"
                                                            [value]="_data.id">
                                                    {{_data.type == 'Company' ? _data.name : _data.name + ' ' + _data.last_name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>Client
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="mt-2 kt-margin-bottom-20-mobile"
                                         *ngIf="getClient(toeForm1.controls.client_id.value) != null && getClient(toeForm1.controls.client_id.value).type == 'Company'">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Representative"
                                                        formControlName="contact_id">
                                                <mat-option *ngFor="let contact of contacts"
                                                            [value]="contact.id">{{contact.first_name}} {{contact.last_name}}
                                                </mat-option>
                                                <mat-option *ngIf="contacts.length == 0" disabled>No Contact</mat-option>
                                            </mat-select>

                                            <mat-error>Representative
                                                <strong>required</strong>
                                            </mat-error>

                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <h5 class="my-3 text-mad text-uppercase">TEAM</h5>
                            <hr class="active">
                            <!-- <kt-support-valuer-list
                                    [isPMSubject]="isPMSubject"
                                    [isLVSubject]="isLVSubject"
                                    [valuersSubject]="valuersSubject"
                                    [toeId]="toe.id"
                                    [isTemplate]="isTemplate"
                                    [readonly]="readonly || toe.status > 0"
                            >
                            </kt-support-valuer-list> -->
                            <!-- <div style="height: 100px;"></div> -->
                            <kt-toe-members [readonly]="readonly || toe.status > 0"
                                [members$]="members$"
                                [refId]="toe.id"
                                [isPMSubject]="isPMSubject"
                                [isLVSubject]="isLVSubject"
                                [isTemplate]="isTemplate"></kt-toe-members>
                        </form>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>2 - {{'TOE.TAB.TITLE2' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>

                    <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
                        {{errorMessage}}
                    </kt-alert>

                    <div class="d-flex flex-column" *ngIf="!isTemplate; else NATab" style="min-height: 200px;">
                        <!-- <div class="d-flex justify-content-end mb-2" *ngIf="toe.status == 0">
                            <button
                                class="btn btn-primary mr-2"
                                color="primary"
                                (click)="addTpFromTemplate()"
                                type="button">
                                Add target property from template
                            </button>
                            <button
                                    class="btn btn-primary"
                                    color="primary"
                                    (click)="addTP()"
                                    type="button">Add target property
                            </button>
                        </div> -->
                        <div class="d-flex justify-content-end mb-2" *ngIf="toe.status == 0">
                            <kt-context-menu-mad 
                                [menuName]="'Add'"
                                [width]="'200'"
                                [menuItems]="tpMenus"
                                [menuSubject]="tpMenus$"></kt-context-menu-mad>
                        </div>
                        <div class="example-container mat-elevation-z0 mat-table-wrapper">
                            <mat-table #table [dataSource]="dataSource">

                                <!-- Position Column -->
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef>Target Property ID</mat-header-cell>
                                    <mat-cell *matCellDef="let tp"> {{tp.name ? tp.name : 'N/A'}}</mat-cell>
                                </ng-container>
                                <!-- Weight Column -->
                                <ng-container matColumnDef="surface">
                                    <mat-header-cell *matHeaderCellDef>Surface</mat-header-cell>
                                    <mat-cell *matCellDef="let tp"> 
                                    {{tp.surface == 0 
                                        ? 'N/A' 
                                        : (tp.surface) + getSystemMeasurementUnitAreaName(toeForm1.controls.unit_of_measurement_id.value)}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="target_property_type_id">
                                    <mat-header-cell *matHeaderCellDef>Target Property Sub-Type</mat-header-cell>
                                    <mat-cell *matCellDef="let tp"> {{tp.type_name ? tp.type_name : 'N/A'}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="type_of_inspection">
                                    <mat-header-cell *matHeaderCellDef>Type of Investigation</mat-header-cell>
                                    <mat-cell *matCellDef="let tp"> {{tp.type_of_inspection < 1 ? 'N/A' : decisions[tp.type_of_inspection - 1].label}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="valuations">
                                    <mat-header-cell *matHeaderCellDef>Number of Valuations</mat-header-cell>
                                    <mat-cell *matCellDef="let tp"> {{tp.valuations.length}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="is_complete">
                                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                                    <mat-cell *matCellDef="let tp">
                                        <b><span
                                                class="label label-inline label-rounded label-outline-{{ getItemCssClassByStatus(tp.is_complete) }} label-outline-2x label-lg"
                                                style="width: 10rem;">{{  getItemStatusString(tp.is_complete) }}
                                        </span></b>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                                    <mat-cell *matCellDef="let tp; let i = index;">
                                        <button mat-icon-button
                                                *ngIf="toe.status < 1 && getItemStatusString(tp.is_complete) === 'Validated'"
                                                color="primary"
                                                matTooltip="Copy the Target Property"
                                                (click)="copyTP(tp)">
                                            <mat-icon>content_copy</mat-icon>
                                        </button>
                                        <button mat-icon-button
                                                *ngIf="toe.status > 0"
                                                color="primary"
                                                matTooltip="View the Target Property"
                                                (click)="viewTP(tp, i)"
                                                type="button">
                                            <mat-icon>remove_red_eye</mat-icon>
                                        </button>&nbsp;

                                        <span *ngIf="toe.status < 1">
                                            <button mat-icon-button
                                                    color="primary"
                                                    matTooltip="Edit the Target Property"
                                                    (click)="editTP(tp, i)"
                                                    type="button">
                                            <mat-icon>create</mat-icon>
                                        </button>&nbsp;

                                            <button mat-icon-button
                                                    color="warn"
                                                    matTooltip="Delete the Target Property"
                                                    (click)="deleteTP(tp, i)"
                                                    type="button">
                                            <mat-icon>delete</mat-icon>
                                        </button>&nbsp;
                                        </span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                            <!--&lt;!&ndash; Message for empty data  &ndash;&gt;-->
                            <!--<div class="mat-table-message" *ngIf="!dataSource.data.length">-->
                            <!--{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}-->
                            <!--</div>-->
                            <div class="mat-table-message" *ngIf="tpListLoading$ | async">
                                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
                            </div>
                        </div>


                    </div>
                </ng-template>

            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>3 - {{'TOE.TAB.TITLE3' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
                        {{errorMessage}}
                    </kt-alert>

                    <!--begin::Form-->
                    <div [ngClass]="{
                        'readonly': readonly || toe.status > 0
                    }">
                        <div class="col-lg-12 mb-2">
                            <h5 class="my-3 text-mad text-uppercase">trigger events</h5>
                            <p>
                                <strong>Preamble to the Conflict of Interest checks. The questions below serve as a basis to adjust the list of potential conflicts of interest for this valuation assignment.</strong>
                            </p>
                            <hr class="active">
                        </div>
                        <div class="col-lg-12 p-3 mb-20">
                            <h6 class="mb-4">
                                    <strong>1. Does the agency or the valuer have any previous knowledge of, or relationship with, the client or the asset to be valued ?</strong> 
                                <button *ngIf="hasToeConflicts$|async" mat-icon-button class="ml-2" (click)="openToeConflicts()">
                                    <mat-icon style="color: #EC2929">warning</mat-icon>
                                </button>
                            </h6>
                            <div class="mb-10">
                                <mat-radio-group class="pl-1" (change)="prevKnowledgeRadioChange($event)" [(ngModel)]="selectedPrevKnowledge">
                                    <mat-radio-button [value]="0">
                                    No 
                                    </mat-radio-button>
                                    <mat-radio-button [value]="1">
                                    Yes 
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <h6 class="mb-4">
                                <strong>2. Does the valuer or the agency have any existing or future financial interest in the property to be valued or the client?</strong>
                            </h6>
                            <mat-radio-group class="mb-2 p-1" (change)="financialInterestRadioChange($event)" [(ngModel)]="selectedFinancialInterest">
                                <mat-radio-button [value]="0">
                                   No 
                                </mat-radio-button>
                                <mat-radio-button [value]="1">
                                   Yes 
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile">
                            <h5 class="my-3 text-mad text-uppercase">CONFLICTS OF INTEREST</h5>
                            <p>
                                <strong>Conflict of Interest (RICS professional standards and guidance, global - Conflict of Interest, 1st edition, March 2017, revised December 2017) </strong>
                            </p>
                            <hr class="active">
                        </div>
                        <div class="col-lg-12 kt-margin-bottom-20-mobile p-3"
                             [ngClass]="{'col-lg-12 kt-margin-bottom-20-mobile p-3 gray': i%2, 'col-lg-12 kt-margin-bottom-20-mobile p-3' : true}"
                             *ngFor="let ci of (ciTemplateData$|async);let i = index;">
                            <kt-conflict-detail
                                    #cdComponents
                                    [conflictData]="ci"
                                    [index]="i"
                                    [tp]="targetProperties"
                                    [readonly]="readonly || toe.status > 0">
                            </kt-conflict-detail>
                        </div>
                    </div>
                </ng-template>

            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>4&nbsp;-&nbsp;{{'TOE.TAB.TITLE4' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (closefileCntSubject)="onAlertClose($event)">
                        {{errorMessage}}
                    </kt-alert>
                    <div [ngClass]="{
                            'readonly': readonly || toe.status > 0
                     }">
                        <div class="col-lg-12 mb-2 kt-margin-bottom-20-mobile">
                            <h5 class="my-3 text-mad text-uppercase">DISCLOSURES</h5>
                            <p>
                                <strong>Any relevant information that should be disclosed regarding this valuation assignment with regards to potential conflicts of interest or lack of
                                    expertise.</strong><br>
                                Should the valuation be relied upon by a third party, information regarding the relationship between the client and the valuer, or the valuer and the asset should be
                                disclosed.
                            </p>
                            <hr class="active">
                        </div>

                        <div class="col-lg-12 kt-margin-bottom-20-mobile p-3"
                             [ngClass]="{'col-lg-12 kt-margin-bottom-20-mobile p-3 gray': i%2, 'col-lg-12 kt-margin-bottom-20-mobile p-3' : true}"
                             *ngFor="let dc of dcData;let i = index;">
                            <kt-conflict-detail-old
                                    #ddComponents
                                    [conflictData]="dc"
                                    [isDisclosure]="boolTrue"
                                    [index]="i"
                                    (onClickFileBtn)="showFiles()"
                                    [isTemplate]="isTemplate">
                            </kt-conflict-detail-old>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>5 - {{'TOE.TAB.TITLE5' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <!--begin::Form-->
                    <div [ngClass]="{'readonly': readonly || toe.status > 0}">
                        <form [formGroup]="toeForm2" class="form form-group-seperator-dashed">
                            <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
                                {{errorMessage}}
                            </kt-alert>

                            <div class="form-section form-section-first">
                                <div class="form-group kt-form-group row">
                                    <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                        <h5 class="my-3 text-mad text-uppercase">DELIVERY</h5>
                                        <hr class="active">
                                        <div class="kt-margin-bottom-20-mobile">
                                            <p>The report will be delivered in :</p>
                                            <div class="w-100" *ngFor="let reportDeliveryMethod of reportDeliveryMethods">
                                                <mat-checkbox
                                                        (change)="changeRdm($event, reportDeliveryMethod)"
                                                        [checked]="selectedRdm.includes(reportDeliveryMethod.id)"> {{reportDeliveryMethod.name}}
                                                </mat-checkbox>
                                            </div>
                                            <mat-checkbox 
                                                (change)="changeMultimediaSupport($event)"
                                                [checked]="hasMultimediaSupport">
                                                Multimedia support
                                            </mat-checkbox>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 kt-margin-bottom-20-mobile" *ngIf="!isTemplate">
                                        <h5 class="my-3 text-mad text-uppercase">OPERATION DATES</h5>
                                        <hr class="active">
                                        <div class="kt-margin-bottom-20-mobile">
                                            <mat-form-field class="mat-form-field-fluid">
                                                <kt-datepicker-tz-input
                                                    #instructionDatePicker
                                                    placeholder="Date of instruction"
                                                    formControlName="instruction_date"
                                                    [timezone]="gowerningLawTimezone"
                                                    [max]="getInstructionDateMax()"></kt-datepicker-tz-input>
                                                <button mat-button mat-icon-button matSuffix type="button">
                                                    <mat-icon>today</mat-icon>
                                                </button>
                                                <button mat-button mat-icon-button matSuffix type="button" (click)="toeForm2.controls.instruction_date">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <!-- <span (click)="addPicker_instruction_date.open()">
                                                    <input matInput [matDatepicker]="addPicker_instruction_date"
                                                           readonly
                                                           placeholder="Date of instruction"
                                                           formControlName="instruction_date"
                                                           [max]="getInstructionDateMax()"
                                                    >
                                                </span>
                                                <mat-datepicker-toggle matSuffix [for]="addPicker_instruction_date"></mat-datepicker-toggle>
                                                <button mat-button *ngIf="this.toeForm2.controls.instruction_date.value" matSuffix mat-icon-button
                                                        matTooltip="Clear"
                                                        (click)="clearDate(this.toeForm2.controls.instruction_date)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <mat-datepicker #addPicker_instruction_date></mat-datepicker> -->
                                            </mat-form-field>
                                        </div>

                                        <div class="mt-2 kt-margin-bottom-20-mobile">
                                            <mat-form-field class="mat-form-field-fluid">
                                                <!-- <span (click)="addPicker_final_delivery_date.open()">
                                                    <input matInput [matDatepicker]="addPicker_final_delivery_date"
                                                           readonly
                                                           [min]="getCompletionDateMin()"
                                                           placeholder="Date of completion"
                                                           formControlName="final_delivery_date">
                                                </span>
                                                <mat-datepicker-toggle matSuffix [for]="addPicker_final_delivery_date"></mat-datepicker-toggle>
                                                <button mat-button
                                                        *ngIf="this.toeForm2.controls.final_delivery_date.value"
                                                        matSuffix mat-icon-button aria-label="Clear"
                                                        (click)="clearDate(this.toeForm2.controls.final_delivery_date)">
                                                    <mat-icon>close</mat-icon>
                                                </button> -->
                                                <kt-datepicker-tz-input
                                                    #finalDeliveryDatePicker
                                                    placeholder="Date of completion"
                                                    [min]="getCompletionDateMin()"
                                                    formControlName="final_delivery_date"
                                                    [timezone]="gowerningLawTimezone"></kt-datepicker-tz-input>
                                                <button mat-button mat-icon-button matSuffix type="button">
                                                    <mat-icon>today</mat-icon>
                                                </button>
                                                <button mat-button mat-icon-button matSuffix type="button" (click)="clearDate(toeForm2.controls.final_delivery_date)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <button mat-button
                                                        matSuffix mat-icon-button
                                                        aria-label="Help"
                                                        [popoverTitle]="'TOOLTIP.TOE.DATE_COMPLETION.TITLE' | translate"
                                                        [ngbPopover]="'TOOLTIP.TOE.DATE_COMPLETION.DESCRIPTION' | translate"
                                                        (click)="$event.stopPropagation();">
                                                    <mat-icon>help</mat-icon>
                                                </button>
                                                <!-- <mat-datepicker #addPicker_final_delivery_date></mat-datepicker> -->
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                        <div class="mt-2 kt-margin-bottom-20-mobile">
                                            <mat-form-field class="mat-form-field-fluid">
                                                <textarea matInput rows="3"
                                                          placeholder="Any support clarifications?"
                                                          formControlName="support_clarifications"></textarea>
                                                <mat-icon
                                                        matSuffix
                                                        class="cursor-pointer icon-gray"
                                                        container="body"
                                                        [popoverTitle]="'TOOLTIP.TOE.SUPPORTING_CLARIFICATION.TITLE' | translate"
                                                        [ngbPopover]="'TOOLTIP.TOE.SUPPORTING_CLARIFICATION.DESCRIPTION' | translate"
                                                        (click)="$event.stopPropagation();">help
                                                </mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 kt-margin-bottom-20-mobile mt-4">
                                        <h5 class="my-3 text-mad text-uppercase">DOCUMENTS TO BE INCLUDED IN THE TERMS OF ENGAGEMENT’s APPENDICES SECTION</h5>
                                        <hr class="active">
                                        <div class="kt-margin-bottom-20-mobile" *ngIf="agencyInfo">
                                            <p><b>1 - Agency ({{agencyInfo ? agencyInfo.name : 'N/A'}}): available documents</b></p>
                                            <div class="w-100" *ngFor="let certificate of this.agencyInfo.certificates; let i = index">
                                                <mat-checkbox
                                                        (change)="changeAgencyDocument($event, certificate)"
                                                        [checked]="selectedAgencyDocuments.includes(certificate.id)"
                                                >
                                                    {{certificate ? certificate.title : 'N/A'}} {{isExpired(certificate.date) ? '(expired)' : ''}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="section-margin-top-60 kt-margin-bottom-20-mobile" *ngIf="valuersSubject.value.length > 0">
                                            <div class="w-100 mt-3" *ngFor="let valuer of valuersSubject.value; let i=index;">
                                                <div [ngClass]="{'section-margin-top-60': i > 0}">
                                                    <p><b>{{i == 0 
                                                                ? ((i + (agencyInfo ? 2 : 1)).toString() + ' - Lead Valuer') 
                                                                : ((i + (agencyInfo ? 2 : 1)).toString() + ' - Support Valuer')}} ({{valuer.worker.first_name}}
                                                        {{valuer.worker.last_name}}): available certiﬁcations and documents</b></p>
                                                    <div *ngFor="let file of valuer.worker.files;">
                                                        <mat-checkbox
                                                                (change)="changeDocument($event, file, i)"
                                                                [checked]="valuer.selected_document.includes(file.id)"
                                                        >
                                                            {{file.title ? file.title : 'N/A'}} {{isExpired(file.date) ? '(expired)' : ''}}
                                                        </mat-checkbox>
                                                    </div>
                                                    <div *ngFor="let certificate of valuer.worker.certificates">
                                                        <mat-checkbox
                                                                (change)="changeCertificate($event, certificate, i)"
                                                                [checked]="valuer.selected_certificate.includes(certificate.id)"
                                                        >
                                                            {{certificate.cert_name ? certificate.cert_name : certificate.organisation.name}}/{{certificate.org_name ? certificate.org_name :
                                                            certificate.certification.name}} {{isExpired(certificate.date) ? '(expired)' : ''}}
                                                        </mat-checkbox>
                                                    </div>
                                                    <p *ngIf="valuer.worker.files && valuer.worker.files.length == 0 && valuer.worker.certificates.length == 0">No file available for that user</p>
                                                    <p *ngIf="!valuer.worker.files">No file available for that user</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="section-margin-top-60 kt-margin-bottom-20-mobile">
                                            <p><b>{{valuersSubject.value.length + (agencyInfo ? 2 : 1)}} - Other documents</b></p>
                                            <div class="kt-margin-bottom-20-mobile">
                                                <kt-upload-file
                                                        [filesOnEditMode]="uploadedFiles"
                                                        [folderOnEditMode]="toe.additional_documents"
                                                        [descriptionField]="false"
                                                        [fileType]="''"
                                                        [parentForm]="'toe'"
                                                        [readOnly]="readonly || toe.status > 0"
                                                        [addButtonLabel]="'Add document'"
                                                        (fileUploaded)="onFileUpload($event)">

                                                </kt-upload-file>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>6 - {{'TOE.TAB.TITLE6' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <div [ngClass]="{'readonly': readonly || toe.status > 0}">
                        <form class="form" [formGroup]="paymentForm">
                            <div class="card-body pt-0">
                                <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                                    {{errorMessage}}
                                </kt-alert>

                                <div class="form-group kt-form-group row">
                                    <div class="col-md-12 kt-margin-bottom-20-mobile">
                                        <h5 class="my-3 text-mad text-uppercase">General Information</h5>
                                        <hr class="active">
                                    </div>

                                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Currency"
                                                        formControlName="currency"
                                                        [compareWith]="matSelectCompareWith"
                                                        #singleSelectCurrency>
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="filterCtrlCountriesData"
                                                                           placeholderLabel="Search"
                                                                           noEntriesFoundLabel="Currencies not found">

                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                                                    {{_data.currencyCode}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                Currency
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-8 kt-margin-bottom-10-mobile"></div>
                                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput placeholder="Discount (%)" formControlName="discount" mask="percent" suffix="%"/>
                                            <mat-error>
                                                Discount
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput placeholder="VAT (%)" formControlName="vat" mask="percent" suffix="%"/>
                                            <mat-error>
                                                VAT
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput placeholder="Other Taxes (%)" formControlName="vat_other" mask="percent" suffix="%"/>
                                            <mat-error>
                                                Other Taxes
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="mt-2 kt-margin-bottom-20-mobile">
                                        <div class="mb-4 col-lg-12">
                                            <mat-label>
                                                Pricing Strategy
                                            </mat-label>
                                        </div>
                                        <div class="mb-4 col-lg-12">
                                            <mat-radio-group class="tp-radio-group" formControlName="pricing_strategy">
                                                <mat-radio-button class="tp-radio-button kt-margin-r-20" [value]="1">Lumpsum payment</mat-radio-button>
                                                <mat-radio-button class="tp-radio-button kt-margin-r-20" [value]="2">Percentage based</mat-radio-button>
                                                <mat-radio-button class="tp-radio-button kt-margin-r-20" [disabled]="disableSizebasedPricing" [value]="3">Size based</mat-radio-button>
                                                <mat-radio-button class="tp-radio-button kt-margin-r-20" [value]="4">Daily rate(Company)</mat-radio-button>
                                                <mat-radio-button class="tp-radio-button kt-margin-r-20" [value]="5">Daily rate(per team member)</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>

                                    <kt-ac-payment-terms-alt2
                                            class="col-md-12 kt-margin-bottom-20-mobile"
                                            [paymentTermsSubject]="paymentTermsSubject"
                                            [totalPrice]="totalPrice"
                                            [currencySubject]="currencySubject"
                                            [isTemplate]="isTemplate"
                                            [readonly]="readonly || toe.status > 0"
                                            [instruction_date]="toeForm2.controls.instruction_date.value"
                                            [timezoneOffset]="gowerningLawTimezone">
                                    </kt-ac-payment-terms-alt2>
                                    <!-- <div class="col-md-12 kt-margin-bottom-20-mobile">
                                        <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="termsPercent != 100">
                                            The total payment terms should equal 100%. It is currently: <strong>{{termsPercent}}</strong>
                                        </mat-error>
                                    </div> -->
                                    <kt-post-valuation-delivery-service
                                        class="col-md-12 kt-margin-bottom-20-mobile"
                                        [readonly]="readonly || toe.status > 0"
                                        [parentTypeID]="2" 
                                        [postValuationDeliveryServicetData]="getPvdData()"
                                        [currencySubject]="currencySubject"
                                    ></kt-post-valuation-delivery-service>
                                </div>

                                <div class="form-group kt-form-group row section-margin-top-60" *ngIf="!isTemplate">
                                    <div class="col-md-12 kt-margin-bottom-20-mobile">
                                        <h5 class="my-3 text-mad text-uppercase">Valuations Breakdown</h5>
                                        <hr class="active">
                                    </div>
                                    <kt-ac-valuation-breakdown-alt
                                        class="col-md-12 kt-margin-bottom-20-mobile"
                                        [toeStatus]="toe.status"
                                        [paymentForm]="paymentForm"
                                        [(valuationsSubject)]="valuationsSubject"
                                        [currencySubject]="currencySubject"
                                        [(unitSubject)]="unitSubject">
                                    </kt-ac-valuation-breakdown-alt>

                                    <kt-toe-members-daily-rates [readonly]="readonly || toe.status > 0" *ngIf="paymentForm.controls.pricing_strategy.value == 5"
                                        class="col-md-12 kt-margin-bottom-20-mobile"
                                        [members$]="members$"
                                        [refId]="toe.id"
                                        [currencySubject]="currencySubject"
                                        [isPMSubject]="isPMSubject"
                                        [isLVSubject]="isLVSubject"
                                        [isTemplate]="isTemplate">
                                    </kt-toe-members-daily-rates>
                                </div>

                                <div class="form-group kt-form-group row section-margin-top-60">
                                    <div class="col-md-12 kt-margin-bottom-20-mobile mt-4">
                                        <h5 class="my-3 text-mad text-uppercase">Additional Costs</h5>
                                        <hr class="active">
                                    </div>
                                    <kt-ac-additional-cost-alt
                                            class="col-md-12 kt-margin-bottom-20-mobile"
                                            [(paymentTaxesSubject)]="paymentTaxesSubject"
                                            [currencySubject]="currencySubject"
                                            [(targetProperties)]="targetProperties"
                                            [readonly]="readonly || toe.status > 0"
                                            [toeStatus]="toe.status">
                                    </kt-ac-additional-cost-alt>

                                </div>

                                <div class="form-group kt-form-group row section-margin-top-60">
                                    <div class="col-md-12 kt-margin-bottom-20-mobile">
                                        <h5 class="my-3 text-mad text-uppercase">Summary</h5>
                                        <hr class="active">
                                    </div>

                                    <div class="example-container mat-elevation-z0 col-md-12">
                                        <mat-table #table [dataSource]="dataSourcePayment" matSort>
                                            <ng-container matColumnDef="id_custom">
                                                <mat-header-cell *matHeaderCellDef> Valuations</mat-header-cell>
                                                <mat-cell *matCellDef="let item">
                                                    {{valuationsSubject.value.length}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="total_price_amount">
                                                <mat-header-cell *matHeaderCellDef> Val Fees</mat-header-cell>
                                                <mat-cell *matCellDef="let item">
                                                    {{currencySubject.value}} {{valuationFee | mask: 'separator':{thousandSeparator: ','} }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="discount">
                                                <mat-header-cell *matHeaderCellDef> Extra costs</mat-header-cell>
                                                <mat-cell *matCellDef="let item">
                                                    {{currencySubject.value}} {{extraCost | mask: 'separator':{thousandSeparator:',' } }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="vat">
                                                <mat-header-cell *matHeaderCellDef> Discount</mat-header-cell>
                                                <mat-cell *matCellDef="let item">
                                                    {{currencySubject.value}}
                                                    ({{((valuationFee + extraCost) * paymentForm.controls.discount.value / 100).toFixed(2) | mask: 'separator':{thousandSeparator:',' } }})
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="vat_other">
                                                <mat-header-cell *matHeaderCellDef> VAT & other taxes</mat-header-cell>
                                                <mat-cell *matCellDef="let item">
                                                    {{currencySubject.value}}
                                                    {{((valuationFee + extraCost - ((valuationFee + extraCost) * paymentForm.controls.discount.value / 100)) * (paymentForm.controls.vat.value +
                                                    paymentForm.controls.vat_other.value) / 100).toFixed(2) | mask: 'separator':{thousandSeparator:',' } }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="exchange_rate">
                                                <mat-header-cell *matHeaderCellDef> TOTAL</mat-header-cell>
                                                <mat-cell *matCellDef="let item">
                                                    {{currencySubject.value}}
                                                    {{getTotalPrice().toFixed(2) | mask: 'separator':{thousandSeparator:',' } }}
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="displayedColumnsPayment"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: displayedColumnsPayment;"></mat-row>
                                        </mat-table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </mat-tab>

            <!-- <mat-tab [hidden]="true" *ngIf="toe.status > 0">
                <ng-template mat-tab-label>
                    <span>7 - {{'TOE.TAB.TITLE7' | translate}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-end mb-2">
                            <mat-checkbox style="margin-top:auto; margin-bottom:auto;" class="mr-2" [checked]="generateReportAsDraft" (change)="onchangeReportOption($event, report)">
                                Generate Report as a Draft
                            </mat-checkbox>
                            <button
                                    class="float-right btn btn-primary"
                                    color="primary"
                                    (click)="generateReport()"
                                    [matTooltip]="'TOE.TOOLTIP.REPORT.GENERATE_NEW' | translate"
                                    type="button"
                                    [disabled]="generating">
                                {{'TOE.BUTTON.REPORT.GENERATE_NEW' | translate}}
                            </button>
                        </div>
                        <div class="mat-table-wrapper">
                            <mat-table class="generated-reports-table" #table [dataSource]="dataSourceGeneratedReport">

                                <ng-container matColumnDef="created_at">
                                    <mat-header-cell *matHeaderCellDef>Generated Date</mat-header-cell>
                                    <mat-cell *matCellDef="let report"> {{report.created_at}}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="file_size">
                                    <mat-header-cell *matHeaderCellDef>Size</mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        <span class="text-danger" *ngIf="report.status === 3">File generation failed</span>
                                        <span *ngIf="report.status !==3">{{report.file_size}}</span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="toe_status">
                                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        <b>
                                            <span
                                                    class="label label-inline label-rounded label-outline-{{ getItemCssClassByStatus(report.toe_status) }} label-outline-2x label-lg py-1"
                                                    style="width: 10rem;"
                                                    *ngIf="report.status !== 3">
                                                {{  getItemStatusString(report.toe_status) }}
                                            </span>
                                        </b>
                                        <span class="text-danger" *ngIf="report.status === 3">
                                            N/A
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="sent_to">
                                    <mat-header-cell *matHeaderCellDef>Delivered to client</mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        <mat-checkbox *ngIf="report.status !==3" [checked]="report.sent_to" (change)="onchangeSentTo($event, report)">
                                        </mat-checkbox>
                                        <span class="text-danger" *ngIf="report.status === 3">
                                            N/A
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                                    <mat-cell *matCellDef="let report; let i = index;">
                                        <ng-template #popTitle>
                                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                                        </ng-template>
                                        <ng-template #popContent>
                                            <div>
                                                <span class="font-weight-bold">File Name:</span> {{report.file_name}}
                                            </div>
                                            <div *ngIf="report.report_desc">
                                                <span class="font-weight-bold">File Description:</span> {{ report.report_desc }}
                                            </div>
                                            <hr/>
                                            <div *ngIf="report.sent_to === 1">
                                                <div>
                                                    <span class="font-weight-bold">Delivered by</span> {{ report.sent_by_name }} <span class="font-weight-bold">on</span>
                                                    {{ report.sent_at | date: 'dd MMM yyyy' }}<br>
                                                </div>
                                                <div>
                                                    <span class="font-weight-bold">Delivery method </span> {{ report.delivery_detail_name}}<br>
                                                </div>
                                                <div *ngIf="report.sent_descr">
                                                    <span class="font-weight-bold">Additional description </span> {{ report.sent_descr}}<br>
                                                </div>
                                            </div>
                                        </ng-template>

                                        <div class="d-flex" *ngIf="report.status !== 3">
                                            <button mat-icon-button
                                                    color="primary"
                                                    matTooltip="Edit Description of Report"
                                                    (click)="editReport(report)">
                                                <mat-icon>create</mat-icon>
                                            </button>

                                            <button mat-icon-button
                                                    color="primary"
                                                    [ngbPopover]="popContent"
                                                    [popoverTitle]="popTitle"
                                                    placement="left"
                                            >
                                                <mat-icon
                                                        [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                                                >info
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button
                                                    color="primary"
                                                    matTooltip="Download Generated Report"
                                                    (click)="downloadToeReport(report, i)"
                                                    type="button"
                                                    [disabled]="report.status != 1">
                                                <mat-icon>get_app</mat-icon>
                                            </button>
                                        </div>

                                        <button mat-icon-button
                                                color="warn"
                                                matTooltip="Delete Generated Report"
                                                type="button"
                                                (click)="deleteToeReport(report, i)"
                                                [disabled]="(generating && report.status == 0) || report.sent_to == 1">
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </mat-cell>

                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumnsGeneratedReport"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsGeneratedReport;"></mat-row>
                            </mat-table>

                        </div>

                        <div ktPortletTools class="section-margin-top-60 mb-1 d-flex justify-content-end" *ngIf="delivered">
                            <div *ngIf="(isInProgressSub | async)" class="mr-2">
                                <div style="margin-bottom: 14px;">Uploading... {{ progressSub | async }}%</div>
                            </div>

                            <div class="error" *ngIf="(hasFailedSub | async)" class="mr-2">
                                Error: {{ errorSub | async }}
                            </div>

                            <div *ngIf="(isInProgressSub | async)" class="mr-2">
                                <span class="btn btn-primary" color="primary" mat-raised-button (click)="cancelUpload()">Cancel Upload</span>
                            </div>

                            <div>
                                <span [matTooltip]="generatedReports.value.length > 0 ? 'Upload a signed ToE':'Please download a generated ToE before uploading the signed ToE.'">
                                <button class="btn btn-primary"
                                        color="primary"
                                        mat-raised-button
                                        (click)="uploadDoc()"
                                        [disabled]="(isInProgressSub | async) || generatedReports.value.length == 0">
                                    Upload signed ToE
                                </button>
                                </span>
                                <input #fileInput type="file" (change)="uploadSignedDoc($event)" style="display: none" accept="application/pdf, image/jpeg, image/jpg, image/png"/>
                            </div>
                        </div>
                        <div class="example-container mat-elevation-z0" *ngIf="delivered">
                            <mat-table class="signed-reports-table" #table [dataSource]="dataSourceSignedReport">
                                <ng-container matColumnDef="upload_date">
                                    <mat-header-cell *matHeaderCellDef>Upload Date</mat-header-cell>
                                    <mat-cell *matCellDef="let report">{{report.created_at | date: 'dd MMM yyyy HH:mm'}}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="file_size">
                                    <mat-header-cell *matHeaderCellDef>Size</mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        <span>{{report.file_size}}</span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="upload_by">
                                    <mat-header-cell *matHeaderCellDef>Upload by</mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        {{report.user_name}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="check">
                                    <mat-header-cell *matHeaderCellDef>Current ToE ?
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        <mat-checkbox
                                                (click)="changeSignedDoc($event, report.id)"
                                                [checked]="report.id == (currentSignedDocId | async)"
                                                [disabled]="report.id == (currentSignedDocId | async)">
                                        </mat-checkbox>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="version">
                                    <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
                                    <mat-cell *matCellDef="let report">
                                        {{ report.has_altered ? 'Altered' : 'Original' }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                                    <mat-cell *matCellDef="let report; let i = index;">
                                        <button mat-icon-button
                                                color="primary"
                                                matTooltip="Edit Description of Report"
                                                (click)="editSignedReport(report)">
                                            <mat-icon>create</mat-icon>
                                        </button>

                                        <ng-template #popTitle>
                                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                                        </ng-template>
                                        <ng-template #popContent>
                                            <div>
                                                <span class="font-weight-bold">File Name:</span> {{report.file_name}}
                                            </div>
                                            <div *ngIf="report.has_altered">
                                                <span class="font-weight-bold">This ToE has been altered:</span> {{ report.details }}
                                            </div>
                                        </ng-template>
                                        <button mat-icon-button
                                                color="primary"
                                                [ngbPopover]="popContent"
                                                [popoverTitle]="popTitle"
                                                container="body"
                                                placement="left"
                                        >
                                            <mat-icon
                                                    [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                                            >info
                                            </mat-icon>
                                        </button>

                                        <button mat-icon-button
                                                [matTooltip]="docs.includes(typesUtilsService.getFileType(report.url)) ? 'Download Signed Report':'View Signed Report'"
                                                (click)="previewSignedReport(report)"
                                                color="primary"
                                                type="button">
                                            <mat-icon *ngIf="!docs.includes(typesUtilsService.getFileType(report.url))">remove_red_eye</mat-icon>
                                            <mat-icon *ngIf="docs.includes(typesUtilsService.getFileType(report.url))">get_app</mat-icon>
                                        </button>&nbsp;

                                        <button mat-icon-button
                                                color="warn"
                                                [matTooltip]="canDeleteSignedReport
                                            ? (report.id == (currentSignedDocId | async) 
                                                ? 'ToE/Current ToE, cannot delete' 
                                                : 'ToE/Current ToE, cannot delete')
                                            : 'Cannot delete, Project Manager and Lead Valuer can delete'"
                                                type="button"
                                                [disabled]="!canDeleteSignedReport || report.id == (currentSignedDocId | async)"
                                                (click)="deleteToeSignedReport(report.id)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumnsSignedReport"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsSignedReport;"></mat-row>
                            </mat-table>
                        </div>
                    </div>
                </ng-template>
            </mat-tab> -->
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet>

<ng-template #NATab matTabContent>
    <div class="w-100 text-center">
        Not Available
    </div>
</ng-template>
