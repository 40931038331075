import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SubdomainService } from "src/app/core/_base/subdomain.service";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { FloorColumn } from "../types";
import { environment } from "src/environments/environment";
import { convertComponentConfig, getConditionRatingComponents } from "../utils/components.util";
import { GEAComponents } from "../components/gea-components";
import { EFA_IDS, GEA_IDS, GIA_IDS, NIA_IDS } from '../ids'
import { GIAComponents } from "../components/gia_components";
import { NIAComponents } from "../components/nia_components";
import { EFAComponents } from "../components/efa_components";
import { keyBy } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class CodeOfMeasurementService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subdomainService: SubdomainService
  ) {}

  public fetchCodeOfMeasurementByAssetClass(
    assetClassId: number,
    unitAreaMeasurementId: number,
    standardMeasurementId: number
  ) {
    const params = new HttpParams()
      .append('uamid', unitAreaMeasurementId.toString())
      .append('smid', standardMeasurementId.toString())

    return this.http.get<{
      data: FloorColumn[]
    }>(
      `${environment.baseApiUrl}api/v2/${this.subdomainService.subDomain}/target-properties/${assetClassId}/code-of-measurements`,
      {
        headers: this.authService.getAuthHeaders(),
        params
      }
    )
  }

  public getComponentConfig(standardMeasurementId: number, rooms: {living_room: number, bed_room: number}) {
    if (GEA_IDS.includes(standardMeasurementId)) {
      return GEAComponents
    }
    if (GIA_IDS.includes(standardMeasurementId)) {
      return GIAComponents
    }
    if (NIA_IDS.includes(standardMeasurementId)) {
      return NIAComponents
    }
    if (EFA_IDS.includes(standardMeasurementId)) {
      return EFAComponents.map(component => {
        if (component.key != 'h') return component;
        const items = [];
        let index = 0;
        for (let i = 0; i < rooms.living_room; i++) {
          items.push({
            key: String.fromCharCode(97 + index),
            name: `Living room ${rooms.living_room == 1 ? '' : (i + 1)}`,
            description: 'Living rooms areas excluding all internal walls (27.8) but including skirting (27.4) fitted cupboard (27.2) or ventilation / heating grille (27.3)'
          })
          index++;
        }
        items.push({
          key: String.fromCharCode(97 + index),
          name: 'Dining room',
          description: 'Dining room areas excluding all internal walls (27.8) but including skirting (27.4) fitted cupboard (27.2) or ventilation / heating grille (27.3)'
        })
        index++;
        for (let i = 0; i < rooms.bed_room; i++) {
          items.push({
            key: String.fromCharCode(97 + index),
            name: `Bed room ${rooms.bed_room == 1 ? '' : (i + 1)}`,
            description: 'Bedroom area (master bedroom, children bedroom, guest, etc) excluding all internal walls (27.8) but including skirting (27.4) fitted cupboard (27.2) or ventilation / heating grille (27.3)'
          })
          index++;
        }
        return {
          ...component,
          items
        }
      })
    }
    return []
  }

  public getComponents(standardMeasurementId: number, rooms: {living_room: number, bed_room: number}) {
    const components = this.getComponentConfig(standardMeasurementId, rooms)
    return convertComponentConfig(components)
  }

  public getConditionRatingComponents(standardMeasurementId: number, rooms: {living_room: number, bed_room: number}) {
    const components = this.getComponentConfig(standardMeasurementId, rooms)
    return getConditionRatingComponents(components)
  }
}