import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpEventType, HttpHeaderResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "./mad-auth.store";
import { MadPermission } from "./mad-permission";
import { ApiFilter, DeleteMadRole, MadRole, MadRoleCollectionResponse, MadRoleResourceResponse } from "./mad-roles.store";
import { MadUser } from "./mad-user";
import { SubdomainService } from "../_base/subdomain.service";
import { ZendeskService } from "../zendesk/zendesk.service";
import * as moment from "moment";


@Injectable()
export class MadAuthService {
  private _outseta: any;
  private _user$ = new BehaviorSubject<MadUser | null>(null);

  API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/auth`
  API_MULTIMEDIA_LOGIN_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/multimedia/login`;
  API_MULTIMEDIA_ME = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/multimedia/me`;
  API_USERS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/users`;
  constructor(
    private _http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private subDomainService: SubdomainService,
    private zendeskService: ZendeskService
  ) {
    this.load();
  }

  // Onboarding
  setUserOnboarded(user: User) {
    return this._http.post(
      `${environment.baseApiUrl}` + `api/v2/${this.subDomainService.subDomain}/users/${user.id}/onboarding`,
      {},
      {
        headers: this.getAuthHeaders()
      }
    )
  }

  /** Auth */
  getToken(): string {
    if (this._outseta) {
      return this._outseta.getAccessToken()
    }
    return null;
  }

  getAuthHeaders(): HttpHeaders {
    if (!this._outseta) {
      this.load()
    }
    const accessToken = this.getToken();
    if (accessToken) {
      return new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': `Bearer ${accessToken}`
      })
    }
    return new HttpHeaders({
      'Content-Type': "application/json"
    })
  }

  getAuthHeadersForUpload(): HttpHeaders {
    const accessToken = this.getToken();
    if (accessToken) {
      return new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`
      })
    }
    return new HttpHeaders()
  }

  async login(token: string): Promise<User> {
    if (!this._outseta) {
      this.load()
    }
    this._outseta.setAccessToken(token)
    const _user = await this._outseta.getUser()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    try {
      const res = await this._http.get<any>(`${this.API_URL}/users/${_user.Uid}`, { headers }).toPromise()
      const madUser = res.data
      const user: User = {
        ...madUser,
        provider: {
          fullName: _user.FullName,
          uid: _user.Uid,
          email: _user.Email,
          plan_uid: _user.Account?.CurrentSubscription?.Plan?.Uid,
          subscription_uid: _user.Account?.CurrentSubscription?.Uid
        },
      }
      this.zendeskService.login(res.meta.zendesk_jwt)
      this.zendeskService.converSeationFieldPlanInfo(JSON.stringify(user.provider))
      this.zendeskService.converSeationFieldAccountId(_user.Account?.Uid)
      this.zendeskService.converSeationFieldOrg(_user.Account?.Name)
      return user
    } catch(err) {
      console.error(err)
    }
  }

  async getCurrentPlan() {
    if (!this._outseta) {
      this.load()
    }

    const _user = await this._outseta.getUser()
    return _user.Account?.CurrentSubscription?.Plan?.Uid
  }

  async setAccessToken(token: string) {
    if (!this._outseta) {
      this.load()
    }
    this._outseta.setAccessToken(token);
    const user = await this._outseta.getUser();
    return user;
  }

  logout() {
    this._outseta.setAccessToken(null)
    this.zendeskService.logout()
    sessionStorage.clear()
    localStorage.clear()
  }


  isLoggedIn(): Observable<boolean> {
    return this._user$.pipe(map(user => {
      return user !== null
    }))
  }

  checkPermission(permissions: Array<MadPermission>): Observable<boolean> {
    const headers = this.getAuthHeaders()
    return this._http.post<{ data: boolean }>(`${this.API_URL}/check-permissions`, { permissions }, {
      headers
    }).pipe(map(res => res.data))
  }

  async openProfile(tab) {
    var idx = 0
    localStorage.removeItem('inv')
    localStorage.removeItem('removedUser')

    this._outseta.profile.open({tab: tab})

    const user_before = await this._outseta.getUser();

    // this._outseta.on("account.update", async () => {
    //   console.log('account update')

    //   const user_after = await this._outseta.getUser();

    //   if (user_before.Account?.Name != user_after.Account?.Name) {
    //     this.updateAccName(user_after).subscribe(async (res) => {
    //       // TODO: add refresh with new subdomain
    //       const user_res = await this._outseta.getUser();
    //       window.location.href = window.location.href.replace(user_before.Account?.Name, user_res.Account?.Name)
    //     });
    //   }
    // });

    return new Promise((resolve, reject) => {
      this._outseta.on("profile.close", async () => {
        console.log('profile.close')
        idx++
        if (idx < 2) {
          console.log('fire once')
          if (localStorage.getItem('inv')) {
            let obj = JSON.parse(localStorage.getItem('inv'))
            this.inviteUser(obj).subscribe(async (res) => {
              localStorage.removeItem('inv')
              resolve(res);
            });
          }
          if (localStorage.getItem('removedUser')) {
            let obj = JSON.parse(localStorage.getItem('removedUser'))
            this.removedUser(obj).subscribe(async (res) => {
              localStorage.removeItem('removedUser')
              resolve(res);
            });
          }
        }
      })
    })
  }

  primaryUserEmail() {
    return this._outseta.getUser().then((profile) => {
      return profile.Account?.PrimaryContact?.Email
    });
  }

  orgName() {
    return this._outseta.getUser().then((profile) => {
      return profile.Account?.Name
    });
  }

  trailLeft() {
    return this._outseta.getUser().then((profile) => {
      if (profile.Account?.AccountStageLabel == 'Trialing') {

        let dateTwo = moment();
        let dateOne = moment(profile.Account?.CurrentSubscription?.RenewalDate);

        let result = dateOne.diff(dateTwo, 'days') + 1

        return result.toString()
      }
      return ''
    });
  }

  accountUsage(): Promise<any> {
    return new Promise((resolve, reject) => {
      let obj = JSON.parse(localStorage.getItem('inv'))
      this.subUsage(obj).subscribe(async (res) => {
        resolve(res);
      });
    })
  }

  openTicket() {
    this._outseta.support.open()
  }


  multimediaLogin(url: string, pwd: string, byPassAbort: boolean): Observable<any> {
      return this._http.post<User>(this.API_MULTIMEDIA_LOGIN_URL, {url, pwd, byPassAbort});
  }

  getMultimediaByToken(): Observable<any> {
      const multimediaToken = localStorage.getItem('multimediaToken');
      const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + multimediaToken);
      return this._http.get<User>(this.API_MULTIMEDIA_ME, {headers: httpHeaders});
  }

  /** Outseta */
  load() {
    const window = getWindow()
    this._outseta = window['Outseta']
  }

  public loadRegisterScript(renderer2: Renderer2, url: string) {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_signup_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "register",
          "planFamilyUid": "nmDPX7my",
          "planPaymentTerm": "annual",
          "skipPlanOptions": true,
          "id": "signup_embed",
          "mode": "embed",
          "selector": "#signup-embed",
          "registrationDefaults": {
            "Account": {
              "ServerLocation": "EU",
            }
          }
        },
        "translations": {
          "en": {
            "Labels": {
              "InviteNewTeamMember": 'Invite new user',
              "TeamMemberInvite": 'Invite new user',
              "Team": 'Users/Licenses',
            }
          }
        }
      }
    `
    renderer2.appendChild(this._document.body, script_option)

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_signup_options"
    renderer2.appendChild(this._document.body, script_src)
  }

  public loadRegisterSpecificScript(renderer2: Renderer2, planUid: string, planPaymentTerm: string, url: string) {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_signup_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "register",
          "planUid": "${planUid}",
          "planPaymentTerm": "${planPaymentTerm}",
          "skipPlanOptions": true,
          "id": "signup_embed",
          "mode": "embed",
          "selector": "#signup-embed",
        },
        "translations": {
          "en": {
            "Labels": {
              "InviteNewTeamMember": 'Invite new user',
              "TeamMemberInvite": 'Invite new user',
              "Team": 'Users/Licenses',
            }
          }
        }
      }
    `
    renderer2.appendChild(this._document.body, script_option)

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_signup_options"
    renderer2.appendChild(this._document.body, script_src)
  }

  public loadLoginScript(renderer2: Renderer2, url: string) {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_login_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "login",
          "id": "login_embed",
          "mode": "embed",
          "selector": "#login-embed",
          "authenticationCallbackUrl": "${url}"
        },
        "translations": {
          "en": {
            "Labels": {
              "InviteNewTeamMember": 'Invite new user',
              "TeamMemberInvite": 'Invite new user',
              "Team": 'Users/Licenses',
            }
          }
        }
      };
    `
    renderer2.appendChild(this._document.body, script_option);

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_login_options"
    renderer2.appendChild(this._document.body, script_src);
  }

  public loadLoginScriptProd(renderer2: Renderer2)
  {
    let script_option = renderer2.createElement('script');
    script_option.text = `
      var o_login_options = {
        "id": "Outseta",
        "domain": "interval.outseta.com",
        "load": "auth",
        "auth": {
          "widgetMode": "login",
          "id": "login_embed",
          "mode": "embed",
          "selector": "#login-embed",
        },
        "translations": {
          "en": {
            "Labels": {
              "InviteNewTeamMember": 'Invite new user',
              "TeamMemberInvite": 'Invite new user',
              "Team": 'Users/Licenses',
            }
          }
        }
      };
    `
    renderer2.appendChild(this._document.body, script_option);

    let script_src = renderer2.createElement('script');
    script_src.src = 'https://cdn.outseta.com/outseta.min.js'
    script_src.dataset.options = "o_login_options"
    renderer2.appendChild(this._document.body, script_src);
  }

  /** Roles CRUD */
  getRoles(filter: ApiFilter): Observable<{ items: MadRole[], total: number }> {
    let params = new HttpParams()
    Object.entries(filter).forEach(([k, v]) => {
      if (v != undefined && v != null) {
        params = params.set(k, v)
      }
    })
    const url = `${this.API_URL}/mad-roles`
    return this._http.get<MadRoleCollectionResponse>(url, { params })
      .pipe(map(res => ({
        items: res.data,
        total: res.pagination.total
      })))
  }

  createRole(role: MadRole): Observable<MadRole> {
    const url = `${this.API_URL}/mad-roles`
    return this._http.post<MadRoleResourceResponse>(url, role)
      .pipe(map(res => res.data))
  }

  updateRole(role: MadRole): Observable<MadRole> {
    const url = `${this.API_URL}/mad-roles/${role.id}`
    return this._http.put<MadRoleResourceResponse>(url, role)
      .pipe(map(res => res.data))
  }

  updateAccName(user: any) {
    const headers = this.getAuthHeaders()
    return this._http.put<{ data: any }>(`${this.API_USERS_URL}/acc/${user.Account?.Uid}/${this.subDomainService.subDomain}/${user.Account?.Name}`, { user }, { headers })
  }

  inviteUser(user: any) {
    const headers = this.getAuthHeaders()
    return this._http.put<{ data: any }>(`${this.API_USERS_URL}/invite`, { user }, { headers })
  }

  removedUser(user: any) {
    const headers = this.getAuthHeaders()
    return this._http.put<{ data: any }>(`${this.API_USERS_URL}/remove`, { user }, { headers })
  }

  subUsage(user: any) {
    const headers = this.getAuthHeaders()
    return this._http.put<{ data: any }>(`${this.API_USERS_URL}/sub/usage`, { user }, { headers })
  }

  deleteRole(id: number): Observable<any> {
    const url = `${this.API_URL}/mad-roles/${id}`
    return this._http.delete(url, {headers: this.getAuthHeaders()});
  }

  private _getAuthHeaderForUpload(): HttpHeaders {
    const accessToken = this.getToken();
    if (accessToken) {
      return new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`
      })
    }
    return new HttpHeaders({
    })
  }
  public upload(data: FormData) {
      const headers = this._getAuthHeaderForUpload()
      const uploadURL = `${this.API_USERS_URL}/upload`;

      return this._http.post<any>(uploadURL, data, {
          headers,
          reportProgress: true,
          observe: 'events',
      }).pipe(map((event) => {
              switch (event.type) {

                  case HttpEventType.UploadProgress:
                      const progress = Math.round(100 * event.loaded / event.total);
                      return {status: 'progress', message: progress};

                  case HttpEventType.Response:
                      return event.body;
                  default:
                      return `Unhandled event: ${event.type}`;
              }
          })
      );
  }
}

export function getWindow(): any {
  return window
}
