import { Input, Component, Output, EventEmitter } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { FloorAction, SizeChange } from "../../../shared_components/sizes-module/sizes-module.types";
import { FloorColumn } from "../types";
import { createFloorColumn } from '../utils/floor.util'
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CodeOfMeasurementService } from "../services/code-of-measurement.service";
import { calculateTotalRow, calculateTotals, getComponentKeys } from "../utils/components.util";
import { EFA_IDS, GEA_IDS, GIA_IDS, NIA_IDS } from "../ids";

interface State {
  standardMeasurementId: number
  schemeId: number,
  floorColumns: FloorColumn[],
  rooms: {
    living_room: number,
    bed_room: number
  }
}

@Component({
  selector: 'kt-code-of-measurement-table',
  templateUrl: './code-of-measurement-table.component.html',
  styleUrls: ['./code-of-measurement-table.component.scss'],
  providers: [ComponentStore]
})
export class CodeOfMeasurementTableComponent {
  private sizeData: any = null
  @Input() assetClassId: number
  @Input() 
  set floorAction(value: FloorAction) {
    if (!value) return;
    this.store.patchState({schemeId: value.scheme_id})

    switch (value.type) {
      case 'add': {
        this.addFloor(value.floors)
        break;
      }
      case 'remove': {
        this.removeFloor(value.floors)
        break;
      }
    }
  }
  @Input()
  set sizeChange(value: SizeChange) {
    this.store.patchState({
      standardMeasurementId: value.standard_measurement_id
    })
    this.fetchSizes({tpId: this.assetClassId, unitAreaMeasurementId: value.unit_area_measurement_id, standardMeasurementId: value.standard_measurement_id})
  }

  @Input()
  set roomChange(value: any) {
    if (!value) return;
    this.store.patchState({
      rooms: {
        living_room: value.livingRooms,
        bed_room: value.bedrooms
      }
    })
  }

  @Output() changeComponents = new EventEmitter<any[]>()

  private _schemeId$ = this.store.select(state => state.schemeId)
  private _floorColumns$ = this.store.select(state => state.floorColumns)
  private _standardMeasurementId$ = this.store.select(state => state.standardMeasurementId)
  private _rooms$ = this.store.select(state => state.rooms)

  floors$ = this.store.select(
    this._schemeId$,
    this._floorColumns$,
    (schemeId, floorsColumns) => {
      const mapped = floorsColumns.map(item => ({...item, schemeId}))
      mapped.sort((a, b) => a.number - b.number)
      this.sizeData = mapped
      return mapped
    }
  )

  components$ = this.store.select(
    this._standardMeasurementId$,
    this._rooms$,
    (standardMeasurementId, rooms) => {
      const components = this.service.getConditionRatingComponents(standardMeasurementId, rooms)
      this.changeComponents.emit(components)
      return this.service.getComponents(standardMeasurementId, rooms)
    }
  )

  totals$ = this.store.select(
    this._floorColumns$,
    this._standardMeasurementId$,
    this._rooms$,
    (floorColumns, standardMeasurementId, rooms) => {
      const components = this.service.getComponentConfig(standardMeasurementId, rooms)
      return calculateTotals(components, floorColumns)
    }
  )

  totalRow$ = this.store.select(
    this._floorColumns$,
    this._standardMeasurementId$,
    this._rooms$,
    (floorColumns, standardMeasurementId, rooms) => {
      const components = this.service.getComponentConfig(standardMeasurementId, rooms)
      return {
        ...calculateTotalRow(components, floorColumns),
        name: this.getStandardMeasurementName(standardMeasurementId) 
      }
    }
  )

  constructor(
    private store: ComponentStore<State>,
    private service: CodeOfMeasurementService
  ) {
    this.store.setState({
      standardMeasurementId: null,
      schemeId: null,
      floorColumns: [],
      rooms: {
        living_room: 0,
        bed_room: 0
      }
    })
  }

  private readonly fetchSizes = this.store.effect(
    (payload$: Observable<{
      tpId: number,
      unitAreaMeasurementId: number,
      standardMeasurementId: number
    }>) =>
      payload$.pipe(
        switchMap(({tpId, unitAreaMeasurementId, standardMeasurementId}) =>
          this.service.fetchCodeOfMeasurementByAssetClass(tpId, unitAreaMeasurementId, standardMeasurementId).pipe(
            tapResponse(
              (response) => {
                this.store.patchState(state => ({
                  floorColumns: state.floorColumns.length > 0 ? state.floorColumns : response.data
                }))
              },
              (err) => console.error(err)
            )
          )
        )
      )
  )

  private readonly addFloor = this.store.updater((state, floors: number[]) => {
    const components = this.service.getComponentConfig(state.standardMeasurementId, state.rooms);
    const componentKeys = getComponentKeys(components);
    let currentFloors = state.floorColumns
    floors.forEach(floor => {
      const index = currentFloors.findIndex(item => item.number === floor)
      if (index > -1) return;
      currentFloors = [...currentFloors, createFloorColumn(floor, state.standardMeasurementId, componentKeys)]
    })
    return {
      ...state,
      floorColumns: currentFloors
    }
  })

  private readonly removeFloor = this.store.updater((state, floors: number[]) => {
    let currentFloors = state.floorColumns
    floors.forEach(floor => {
      currentFloors = [...currentFloors.filter(item => item.number !== floor)]
    })
    return {
      ...state,
      floorColumns: currentFloors
    }
  })

  onFocusOut(event: FocusEvent, floorNumber: number, key: string) {
    const target = event.target as HTMLInputElement
    let val = Number(target.value)
    if (val < 0) {
      val = Math.abs(val)
    }

    this.store.patchState((state) => {
      const floor = state.floorColumns.find(item => item.number === floorNumber)
      if (!floor) return state
      return {
        floorColumns: [
          ...state.floorColumns.filter(floor => floor.number != floorNumber),
          {
            ...floor,
            [key]: val
          }
        ]
      }
    })
  }

  private getStandardMeasurementName(standardMeasurementId: number) {
    if (GEA_IDS.includes(standardMeasurementId)) return 'GEA'
    if (GIA_IDS.includes(standardMeasurementId)) return 'GIA'
    if (NIA_IDS.includes(standardMeasurementId)) return 'NIA'
    if (EFA_IDS.includes(standardMeasurementId)) return 'EFA Residential'
    return ''
  }

  getData() {
    return this.sizeData;
  }
}