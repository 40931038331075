import { EFA_IDS } from '../ids'
import { FloorColumn } from '../types'
export function createFloorColumn(floorNumber: number, standardMeasurementId: number, componentKeys: string[]): FloorColumn {
  let column = {
    number: floorNumber,
    a_a: null,
    a_b: null,
    a_c: null,
    a1: null,
    a2: null,
    b: null,
    b1: null,
    b2: null,
    b3: null,
    c: null,
    c_a: null,
    c_b: null,
    c_c: null,
    d: null,
    e: null,
    e_a: null,
    e_b: null,
    f: null,
    g: null,
    h: null,
    h_a: null,
    h_b: null,
    h_c: null,
    h_d: null,
    i_a: null,
    i_b: null,
    i_c: null,
    i_d: null,
    i_e: null
  }
  if (!EFA_IDS.includes(standardMeasurementId)) return column;
  const result = componentKeys
    .filter(key => key.startsWith('h_'))
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: null
      }
    },
    column
  )
  return result
}