import { A } from "@angular/cdk/keycodes";
import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class IPMSPicturesService implements OnDestroy {
  private _related$ = new Subject<{
    key: number,
    value: string,
    path: string,
    name: string,
    title: string,
    floor: number,
  }>()
  private _dropdownValues$ = new ReplaySubject<{
    key: number,
    value: string
  }[]>()

  private _onDestroy$ = new Subject()

  constructor() {}

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  initializeDropdown() {
    this._related$.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
    })

    return {
      dropdown: this._dropdownValues$,
      related: this._related$ 
    }
  }

  setRatings(ratings: any[], components: any[]) {
    const dropdownValues = [{
      key: null, value: 'applicable'
    }]
    ratings.forEach(rating => {
      components.forEach(component => {
        const r = ratings[component.key]
        if (r && r.rating != 'N/A' && r.rating != 'N/I') {
          dropdownValues.push({
            key: 1,
            value: `${component.label}` 
          })
        }
      })
    })
    this._dropdownValues$.next(dropdownValues)
  }
}