import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { IPMSSizeTableComponent } from './ipms-size-table.component'
import { MatIconModule } from "@angular/material/icon";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NgbPopoverModule,
    NgxMaskPipe,
    NgxMaskDirective
  ],
  declarations: [
    IPMSSizeTableComponent
  ],
  exports: [
    IPMSSizeTableComponent
  ],
  providers: [
    provideNgxMask()
  ]
})
export class IPMSSizeTableModule {}