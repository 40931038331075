import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { FloorAction, SizeChange } from "../../shared_components/sizes-module/sizes-module.types";
import { Observable } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { IPMSSizeService } from "./ipms-size.service";
import { FloorColumn } from "./types";
import { calculateIPMSTotals, calculateTotalRow, calculateTotals, createFloorColumn } from "./ipms-size.util";
import { IPMS } from "src/app/core/v2/constants";

interface State {
  ipmsId: number,
  schemeId: number
  floors: FloorColumn[],
  occupiers: string[]
}

@Component({
  selector: 'kt-ipms-size-table',
  templateUrl: './ipms-size-table.component.html',
  styleUrls: ['./ipms-size-table.component.scss'],
  providers: [ComponentStore]
})
export class IPMSSizeTableComponent {
  private sizeData: any = null
  @Input() assetClassId: number
  @Input()
  set ipmsId(value: number) {
    this.store.patchState({
      ipmsId: value,
    })
  }

  @Input()
  set occupiers(value: string[]) {
    if (!value) return;
    this.changeOccupierComponent(value)
  }

  @Input() 
  set floorAction(value: FloorAction) {
    if (!value) return
    this.store.patchState({schemeId: value.scheme_id})

    switch (value.type) {
      case 'add': {
        this.addFloor(value.floors)
        break;
      }
      case 'remove': {
        this.removeFloor(value.floors)
        break;
      }
    }
  }

  @Input()
  set sizeChange(value: SizeChange) {
    this.fetchSizes({tpId: this.assetClassId, unitAreaMeasurementId: value.unit_area_measurement_id})
  }

  @Output() changeComponents = new EventEmitter<any[]>()

  private _ipmsId$ = this.store.select(state => state.ipmsId)
  private _schemeId$ = this.store.select(state => state.schemeId)
  private _floors$ = this.store.select(state => state.floors)
  private _occupiers$ = this.store.select(state => state.occupiers)

  floors$ = this.store.select(
    this._schemeId$,
    this._floors$,
    (schemeId, floors) => {
      const calculated = calculateIPMSTotals(floors)
      const mappedFloors = calculated.map(floor => ({...floor, schemeId}))
      mappedFloors.sort((a, b) => a.number - b.number)
      // Effect
      this.sizeData = mappedFloors
      //
      return mappedFloors
    }
  )
  components$ = this.store.select(
    this._ipmsId$,
    this._occupiers$,
    (ipmsId, occupiers) => {
      const components = this.ipmsSizeService.getConditionRatingComponents(ipmsId, occupiers)
      this.changeComponents.emit(components)
      return this.ipmsSizeService.getComponents(ipmsId, occupiers)
    }
  )
  totals$ = this.store.select(
    this._floors$,
    this._occupiers$,
    (floors, occupiers) => {
      const totals = calculateTotals(floors, occupiers)
      return totals
    }
  )

  totalRow$ = this.store.select(
    this._floors$,
    this._ipmsId$,
    (floors, ipmsId) => {
      const totalRow = calculateTotalRow(floors)
      return {
        name: this.getIPMSName(ipmsId) ,
        ...totalRow
      }
    }
  )

  constructor(
    private store: ComponentStore<State>,
    private ipmsSizeService: IPMSSizeService
  ) {
    this.store.setState({
      ipmsId: null,
      schemeId: null,
      floors: [],
      occupiers: []
    })
  }

  readonly fetchSizes = this.store.effect((payload$: Observable<{tpId: number, unitAreaMeasurementId: number}>) =>
    payload$.pipe(
      switchMap(({tpId, unitAreaMeasurementId}) => 
        this.ipmsSizeService.fetchIPMSSizeByAssetClass(tpId, unitAreaMeasurementId).pipe(
          tapResponse(
            (response) => {
              this.store.patchState(state => ({
                floors: state.floors.length > 0 ? state.floors : response.data 
              }))
            },
            (err) => console.error(err)
          )
        )
      )
    )
  )

  readonly addFloor = this.store.updater((state, floors: number[]) => {
    let currentFloors = state.floors
    floors.forEach(floor => {
      const index = currentFloors.findIndex(item => item.number === floor)
      if (index > -1) return;
      currentFloors = [...currentFloors, createFloorColumn(floor, state.occupiers)]
    })
    return {
      ...state,
      floors: currentFloors
    }
  })

  readonly removeFloor = this.store.updater((state, floors: number[]) => {
    let currentFloors = state.floors
    floors.forEach(floor => {
      currentFloors = [...currentFloors.filter(item => item.number !== floor)]
    })
    return {
      ...state,
      floors: currentFloors
    }
  })

  readonly changeOccupierComponent = this.store.updater((state, occupiers: string[]) => {
    const floors = state.floors.map((floor) => {
      const occupierComponents = occupiers.reduce((acc, occupier, idx) => {
        if (acc[`f${idx + 1}`]) return acc
        return {
          ...acc,
          [`f${idx + 1}`]: {
            total_area: null,
            limited_use_areas: null
          }
        }
      }, {})
      return {
        ...floor,
        ...occupierComponents
      }
    })
    return {
      ...state,
      occupiers,
      floors
    }
  })

  onFocusOut(event: FocusEvent, floorNumber: number, component: string, type: string) {
    const target = event.target as HTMLInputElement
    let val = Number(target.value)
    if (val < 0) {
      val = Math.abs(val)
    }

    this.store.patchState((state) => {
      const floor = state.floors.find(floor => floor.number == floorNumber)
      if (!floor) return state
      return {
        floors: [
          ...state.floors.filter(floor => floor.number != floorNumber),
          {
            ...floor,
            [component]: {
              ...floor[component],
              [type]: val
            }
          }
        ]
      }
    })
  }

  getData() {
    return this.sizeData;
  }

  private getIPMSName(ipmsId: number) {
    if (ipmsId === IPMS.IPMS1) return 'IPMS 1'
    return ''
  }
}