// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassDetailService} from '../_services/asset-class-detail.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AssetClassDetailActionTypes,
    AssetClassDetailActionToggleLoading,
    AssetClassDetailOnServerCreated,
    AssetClassDetailCreated,
    AssetClassDetailRequest, AssetClassDetailLoaded, AssetClassDetailOnServerUpdated
} from '../_actions/asset-class-detail.actions';

@Injectable()
export class AssetClassDetailEffects {
    showActionLoadingDispatcher = new AssetClassDetailActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassDetailActionToggleLoading({isLoading: false});

    @Effect()
    createAssetClassDetail$ = this.actions$.pipe(
        ofType<AssetClassDetailOnServerCreated>(AssetClassDetailActionTypes.AssetClassDetailOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);

            return this.service.create(payload.assetClass,
                payload.sizes,
                payload.fileList,
                payload.selectedFacilities,
                payload.selectedFacilitiesOffice,
                payload.selectedFacilitiesOfficeExclusive,
                payload.externalAreas,
                payload.ratings,
                payload.participants,
                payload.userId,
                payload.sizeModuleData,
            payload.ipmsSizeData,
            payload.codeOfMeasurementData).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassDetailCreated({assetClass: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updateAssetClassDetail$ = this.actions$
        .pipe(
            ofType<AssetClassDetailOnServerUpdated>(AssetClassDetailActionTypes.AssetClassDetailOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.assetClass,
                    payload.sizes,
                    payload.fileList,
                    payload.selectedFacilities,
                    payload.selectedFacilitiesOffice,
                    payload.selectedFacilitiesOfficeExclusive,
                    payload.externalAreas,
                    payload.ratings,
                    payload.participants,
                    payload.userId,
                    payload.sizeModuleData,
                payload.ipmsSizeData,
                payload.codeOfMeasurementData).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassDetailLoaded({assetClass: res.data}));
                    }));
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    getAssetClassDetail$ = this.actions$
        .pipe(
            ofType<AssetClassDetailRequest>(AssetClassDetailActionTypes.AssetClassDetailRequest),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.get(payload.assetClassId).pipe(
                        tap(res => {
                            this.store.dispatch(new AssetClassDetailLoaded({assetClass: res.data}));
                        })
                    );
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions,
                private service: AssetClassDetailService,
                private store: Store<AppState>) {
    }
}
