import { Action } from "@ngrx/store";
import { VAssetClassModelTypes } from "../_models/valuation-asset-class.model";
import { CriterionModel, CustomCriterionModel, DefaultCriterionModel } from "../_models/valuation-process-criterion.model";
import { ValuationAdjustmentTabDataModel } from "../_models/valuation-adjustment-tab-data.model";

export enum ValuationProcessCriterionActionTypes {
    LoadCriterions = '[Valuation Process Page] Load criterions and populate it',
    CreateTPCriterions = '[Valuation Process Effect] Create target property criterions',

    ToggleCriterionActive = '[Valuation Process / Criterions Modal] Toggle Criterions Active State',

    AddComparable = '[Valuation Process Effect] Add comparable to valuation process default criterions',
    RemoveComparable = '[Valuation Process Effect] Remove comparable from valuation process default criterions',
    AddMultipleComparable = '[Valuation Comparable Effect] Add Multiple comparables to valuation process default criterions',

    ResetState = '[Valuation Process Page] Reset state of valuation process criterion state',

    LoadCriterionsFromServer = '[Valuation Process Effect] Load criterions from server',

    AddCustomCriterion = '[Criterions Edit Modal] Add Custom Criterion',
    EditCustomCriteiron = '[Criterions Edit Modal] Edit Custom Criterion',
    EditDefaultCriterion = '[Criterions Edit Modal] Edit Default Criterion',
    DeleteCustomCriterion = '[Criterions Table] Delete Custom Criterion',

    InsertCriterionsFromTemplate = '[Criterions Table] Insert Criterions from criterion template',

    ResetDefaultCriterions = '[Criterions Table] Reset edit criterions to default state'
}

export class ValuationProcessInsertCriterionsFromTemplate implements Action {
    readonly type = ValuationProcessCriterionActionTypes.InsertCriterionsFromTemplate

    constructor(public payload: {
        refNums: string[],
        defaultCriterions: any[],
        customCriterions: any[]
    }) {}
}

export class ValuationProcessLoadCriterions implements Action {
    readonly type = ValuationProcessCriterionActionTypes.LoadCriterions
}

export class ValuationProcessLoadCriterionFromServer implements Action {
    readonly type = ValuationProcessCriterionActionTypes.LoadCriterionsFromServer
    constructor(public payload: {crits: DefaultCriterionModel[]}) {}
}

export class ValuationProcessCreateTargetPropertyCriterions implements Action {
    readonly type = ValuationProcessCriterionActionTypes.CreateTPCriterions

    constructor(public payload: {
        targetProperty: VAssetClassModelTypes,
    }) {}
}

export class ValuationProcessToggleCriterionActiveState implements Action {
    readonly type = ValuationProcessCriterionActionTypes.ToggleCriterionActive
    constructor( public payload: {
        id: number,
        active: boolean
    } ) {}
}

export class ValuationProcessCriterionsAddComparable implements Action {
    readonly type = ValuationProcessCriterionActionTypes.AddComparable
    constructor(public payload: {
        comparable: VAssetClassModelTypes,
        selectedConsiderationId: number,
    }) {}
}

export class ValuationProcessCriterionsAddMultipleComparable implements Action {
    readonly type = ValuationProcessCriterionActionTypes.AddMultipleComparable
    constructor(public payload: {
        comparables: VAssetClassModelTypes[],
        adjustmentTabData: ValuationAdjustmentTabDataModel
    }) {}
}


export class ValuationProcessCriterionsRemoveComparable implements Action {
    readonly type = ValuationProcessCriterionActionTypes.RemoveComparable
    constructor(public payload: {
        refNum: string
    }) {}
}

export class ValuationProcessCriterionsReset implements Action {
    readonly type = ValuationProcessCriterionActionTypes.ResetState
}

export class ValuationProcessAddCustomCriterion implements Action {
    readonly type = ValuationProcessCriterionActionTypes.AddCustomCriterion
    constructor(public payload: {
        criterion: CustomCriterionModel
    }) {}
}

export class ValuationProcessEditCustomCriterion implements Action {
    readonly type = ValuationProcessCriterionActionTypes.EditCustomCriteiron
    constructor(public payload: {
        criterion: CustomCriterionModel
    }) {}
}

export class ValuationProcessEditDefaultCriterion implements Action {
    readonly type = ValuationProcessCriterionActionTypes.EditDefaultCriterion
    constructor(public payload: {
        criterion: DefaultCriterionModel 
    })  {}
}

export class ValuationProcessResetDefaultCriterion implements Action {
    readonly type = ValuationProcessCriterionActionTypes.ResetDefaultCriterions
    constructor(public payload: {
        criterion: DefaultCriterionModel,
        comparables: VAssetClassModelTypes[],
        selectedConsiderations: Array<{refNum: string, id: number}>
    }) {}
}

export class ValuationProcessDeleteCustomCriterion implements Action {
    readonly type = ValuationProcessCriterionActionTypes.DeleteCustomCriterion
    constructor(public payload: {id: number}) {}
}

export type ValuationProcessCriterionActions
    = ValuationProcessCreateTargetPropertyCriterions
    | ValuationProcessToggleCriterionActiveState
    | ValuationProcessLoadCriterions
    | ValuationProcessCriterionsAddComparable
    | ValuationProcessCriterionsRemoveComparable
    | ValuationProcessCriterionsReset
    | ValuationProcessLoadCriterionFromServer
    | ValuationProcessAddCustomCriterion
    | ValuationProcessEditCustomCriterion
    | ValuationProcessEditDefaultCriterion
    | ValuationProcessInsertCriterionsFromTemplate
    | ValuationProcessResetDefaultCriterion
    | ValuationProcessCriterionsAddMultipleComparable
    | ValuationProcessDeleteCustomCriterion
