<div class="form-group kt-form-group row mt-3"
    *ngIf="isInstructedToMeasure && form" [formGroup]="form">
    <div class="col-md-12 kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">measurement information</h5>
        <hr class="active">
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <span (click)="addPicker_measurement_date.open()">
                <input matInput [matDatepicker]="addPicker_measurement_date"
                    readonly

                    placeholder="Date of measurement"
                    formControlName="measurement_date">
            </span>
            <mat-datepicker-toggle matSuffix [for]="addPicker_measurement_date"></mat-datepicker-toggle>
            <button mat-button
                    *ngIf="form.controls.measurement_date.value"
                    matSuffix mat-icon-button matTooltip="Clear"
                    (click)="clearDate(form.controls.measurement_date)">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker #addPicker_measurement_date></mat-datepicker>
            <mat-error>Date of Measurement is <strong>required</strong></mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Unit of Measurement" formControlName="unit_measurement_id">
                <mat-option *ngFor="let item of units" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Unit of Measurement
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <div class="tag-warning" *ngIf="unitMeasurementWarning">
            Warning: differs from ToE requirement, do not forget to record the change in the audit trail
        </div>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Measurement Standard" formControlName="measurement_standard_id">
                <mat-option *ngFor="let item of standardMeasurements" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Measurement Standard
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <div class="tag-warning" *ngIf="standardMeasurementWarning">
            Warning: differs from ToE requirement, do not forget to record the change in the audit trail
        </div>
    </div>

    <!-- <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="form.controls.measurement_standard_id.value == 48">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="IPMS" formControlName="ipms_id">
                <mat-option [value]="1">
                    IPMS 1
                </mat-option>
                <mat-option [value]="2">
                    IPMS 2
                </mat-option>
                <mat-option [value]="3">
                    IPMS 3.1
                </mat-option>
                <mat-option [value]="4">
                    IPMS 3.2
                </mat-option>
                <mat-option [value]="5">
                    IPMS 4.1
                </mat-option>
                <mat-option [value]="6">
                    IPMS 4.2
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="form.controls.measurement_standard_id.value == -2">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Measurement standard name" formControlName="new_measurement_standard_name" />
            <mat-error>
                Measurement Standard name
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-{{form.controls.measurement_standard_id.value == -1 ? '12' : '8'}} kt-margin-bottom-20-mobile" *ngIf="form.controls.measurement_standard_id.value == -2 || form.controls.measurement_standard_id.value == -1">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Measurement standard Description" formControlName="new_measurement_standard_description" ></textarea>
        </mat-form-field>
    </div>

            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput rows="3" placeholder="Purpose of the Measurement Instruction" formControlName="purpose_of_the_measurement_instruction"></textarea>
                    <mat-icon
                            matSuffix
                            class="cursor-pointer"
                            (click)="$event.stopPropagation();"
                            container="body"
                            [popoverTitle]="'TOOLTIP.INSPECTION.PURPOSE.TITLE' | translate"
                            [ngbPopover]="'TOOLTIP.INSPECTION.PURPOSE.DESCRIPTION' | translate">help
                    </mat-icon>
                    <mat-error>Purpose of the Measurement Instruction
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>


    <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput rows="3" placeholder="Measurement Methodology Adopted" formControlName="measurement_methodology_adopted"></textarea>
            <mat-icon
                    matSuffix
                    class="cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    [popoverTitle]="'TOOLTIP.INSPECTION.MEASUREMENT_ADOPTED.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.INSPECTION.MEASUREMENT_ADOPTED.DESCRIPTION' | translate">help
            </mat-icon>
            <mat-error>Measurement Methodology Adopted
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput rows="3" placeholder="Scale of Plan" formControlName="scale_of_plan"></textarea>
            <mat-icon
                    matSuffix
                    class="cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    [popoverTitle]="'TOOLTIP.INSPECTION.SCALE_PLAN.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.INSPECTION.SCALE_PLAN.DESCRIPTION' | translate">help
            </mat-icon>
            <mat-error>Scale of Plan
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput rows="3" placeholder="Floor area schedule with relevant areas cross referenced to floor plans" formControlName="floorpans"></textarea>
            <mat-icon
                    matSuffix
                    class="cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    [popoverTitle]="'TOOLTIP.INSPECTION.FLOOR.TITLE' | translate"
                    [ngbPopover]="'TOOLTIP.INSPECTION.FLOOR.DESCRIPTION' | translate">help
            </mat-icon>
            <mat-error>Floor area schedule with relevant areas cross referenced to floorpans
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-12 kt-margin-bottom-20-mobile">
        <div class="row">
            <div class="col-lg-6" *ngIf="isIPMS$|async">
                <div class="row">
                    <div class="col-12 d-flex justify-content-between">
                        <p>Add occupiers</p>
                        <button type="button" mat-icon-button color="primary" class="add_btn" (click)="onAddOccupier()">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>
                    <div class="col-12 occupier-field" *ngFor="let occupierFormControl of occupiersFormArray.controls; let i = index">
                        <mat-form-field class="mat-form-field-fluid">
                            <input placeholder="Name" matInput [formControl]="occupierFormControl" />
                            <mat-error>
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <button type="button" mat-icon-button color="primary" class="remove_btn" (click)="onRemoveOccupier(i)">
                            <mat-icon>do_not_disturb_on</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="acStandardMeasurement">
        <ng-container *ngIf="smUtilityService.tableType(acStandardMeasurement.id) as tableType">
            <ng-container *ngIf="tableType === 'legacy'">
                <div class="col-md-12 mt-10 kt-margin-bottom-20-mobile">
                    <h5 class="my-3 text-mad text-uppercase">sizes</h5>
                    <hr class="active">
                </div>
                <kt-sizes-module
                    class="col-lg-12"
                    [assetClassID]="assetClassID"
                    [sizeChanges$]="sizeChanges$"
                    [floorAction$]="floorAction$"
                    [bedroom$]="bedrooms$"
                    [roomChange$]="roomChange$"
                    [externalAreas$]="externalAreas$"
                    >
                </kt-sizes-module>
                <mat-error class=" col-md-12 tag-custom-error" *ngIf="sizeModuleError$|async">
                    Invalid size
                </mat-error>
            </ng-container>
            <ng-container *ngIf="tableType === 'none'">
                <kt-ac-size class="col-md-12 mt-10 tag-custom-margin-bottom-neg-6"
                        [readonly]="readonly"
                        [sizesSubject]="sizesSubject"
                        [assetClassType]="propertySubTypeID.toString()"
                        [staticUnit]="staticUnit"
                        [hideTitle]="true"
                        [addingTitle]="isInstructedToMeasure ? '& PROPERTY MEASUREMENT INFORMATION':''"
                        [standardMeasurementID]="acSizeData.standardMeasurementID"
                        [measurements$]="standardMeasurements$"
                >

                </kt-ac-size>
                <mat-error class="col-md-12" *ngIf="hasFormErrors && sizesSubject.value.length == 0">
                    <div class="tag-custom-error w-100">
                        Sizes
                        <strong>required</strong>
                    </div>
                </mat-error>
            </ng-container>
            <ng-container *ngIf="tableType === 'ipms'">
                <div class="col-md-12 mt-10 kt-margin-bottom-20-mobile">
                    <h5 class="my-3 text-mad text-uppercase">sizes</h5>
                    <hr class="active">
                </div>
                <kt-ipms-size-table class="col-12" 
                    [occupiers]="occupiers$|async"
                    [assetClassId]="assetClassID"
                    [ipmsId]="standardMeasurementId$|async"
                    [floorAction]="floorAction$|async"
                    [sizeChange]="sizeChanges$|async"
                    (changeComponents)="onChangeComponents($event)"></kt-ipms-size-table>
            </ng-container>
            <ng-container *ngIf="tableType === 'code-of-measurement'">
                <kt-code-of-measurement-table
                    class="col-12"
                    [assetClassId]="assetClassID"
                    [floorAction]="floorAction$|async"
                    [sizeChange]="sizeChanges$|async"
                    [roomChange]="roomChange$|async"
                    (changeComponents)="onChangeComponents($event)"
                ></kt-code-of-measurement-table>
            </ng-container>
        </ng-container>
    </ng-container>
    <kt-ac-document-upload
        *ngIf="form.controls.measurement_standard_id.value == -1 || form.controls.measurement_standard_id.value == -2"
        class="col-md-12 mt-10"
        [title]="'Property Measurement documents'"
        [files]="measurementData.new_measurement_standard_files"></kt-ac-document-upload>
</div>
<!-- END Property measurement information -->
<div class="form-group row section-margin-top-60" *ngIf="!isInstructedToMeasure">
    <kt-ac-size class="col-md-12 tag-custom-margin-bottom-neg-6"
            [readonly]="readonly"
            [sizesSubject]="sizesSubject"
            [assetClassType]="propertySubTypeID.toString()"
            [staticUnit]="staticUnit"
            [hideTitle]="false"
            [addingTitle]="isInstructedToMeasure ? '& PROPERTY MEASUREMENT INFORMATION':''"
            [standardMeasurementID]="acSizeData.standardMeasurementID"
            [measurements$]="standardMeasurements$"
    >
    </kt-ac-size>
    <mat-error class="col-md-12 tag-custom-error" *ngIf="hasFormErrors && sizesSubject.value.length == 0">
        Sizes
        <strong>required</strong>
    </mat-error>
</div>