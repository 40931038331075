import { Component } from '../components/types'
import * as _ from 'lodash'
import { FloorColumn } from '../types'

type ComponentRow = {
  label: string
  items: ItemRow[]
  bgColor: string
}

type ItemRow = {
  key: string
  label?: string
  name: string,
  bgColor: string,
  inputColor: string,
  totalColor: string
}

export function convertComponentConfig(components: Component[]): ComponentRow[] {
  return components.map(component => {
    return {
      label: component.label,
      bgColor: component.componentColor,
      items: component.items.map(item => {
        return {
          key: item.key 
            ? `${component.key}_${item.key}`
            : `${component.key}`,
          label: item.key
            ? item.key
            : undefined,
          name: item.name,
          bgColor: component.itemColor,
          inputColor: component.inputColor,
          totalColor: component.totalColor,
          description: item.description
        }
      })
    }
  })
}

export function getComponentKeys(components: Component[]) {
  return _.flatten(components.map(component => {
    return component.items.map(item => {
      return item.key
        ? `${component.key}_${item.key}`
        : `${component.key}`
    })
  }))
}

export function calculateTotals(components: Component[], floors: FloorColumn[]) {
  const keys = getComponentKeys(components)

  const result = keys.reduce((acc, key) => {
    const total = floors.reduce((sum, floor) => {
      const value = floor[key]
      return sum + (value ? value : 0)
    }, 0)
    return {
      ...acc,
      [key]: total
    }
  }, {})

  return result
}

export function calculateTotalRow(components: Component[], floors: FloorColumn[]) {
  const keys = getComponentKeys(components)

  const result = floors.reduce((acc, floor) => {
    return {
      ...acc,
      [floor.number]: keys.reduce((acc, key) => {
        return acc + (floor[key] != undefined ? Number(floor[key]) : 0)
      }, 0) 
    }
  }, {})

  const total = Object.values(result).reduce((acc: number, item: number) => acc + item, 0)
  return {
    ...result,
    total
  }
}

export function getConditionRatingComponents(components: Component[]) {
  return _.flatten(components.map(component => {
    return component.items.map(item => {
      return {
        label: item.key 
          ? `${component.label} (${item.key})`
          : `${component.label}`,
        key: item.key
          ? `${component.key}_${item.key}`
          : `${component.key}`,
        name: item.name,
        description: item.description
      }
    })
  }))
}