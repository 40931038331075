import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { ValuationProcessCriterionActions, ValuationProcessCriterionActionTypes } from "../_actions/criterion.actions";
import { CustomCriterionModel } from "../_models/valuation-process-criterion.model";

export interface ValuationProcessCustomCriterionState extends EntityState<CustomCriterionModel>{
    lastCreatedId: number
}
export const adapter: EntityAdapter<CustomCriterionModel> = createEntityAdapter()
export const initialValuationProcessCustomCriterionState: ValuationProcessCustomCriterionState = adapter.getInitialState({
    lastCreatedId: 0
})
export function valuationProcessCustomCriterionReducer(
    state = initialValuationProcessCustomCriterionState,
    action: ValuationProcessCriterionActions
): ValuationProcessCustomCriterionState {
    switch (action.type) {
        case ValuationProcessCriterionActionTypes.AddCustomCriterion: {
            const id = state.lastCreatedId + 1
            const criterion = {
                ...action.payload.criterion,
                id
            }
            return adapter.addOne(criterion, {...state, lastCreatedId: id})
        }
        case ValuationProcessCriterionActionTypes.EditCustomCriteiron: {
            const update: Update<CustomCriterionModel> = {
                id: action.payload.criterion.id,
                changes: action.payload.criterion,
            }
            return adapter.updateOne(update, {...state})
        }
        case ValuationProcessCriterionActionTypes.InsertCriterionsFromTemplate: {
            let id = state.lastCreatedId
            const crits: CustomCriterionModel[] = action.payload.customCriterions.map(crit => {
                const values: CustomCriterionModel['values'] = {};
                action.payload.refNums.forEach(refNum => {
                    values[refNum] = {kind: 'simple', value: ''}
                })
                id += 1
                return {
                    id: id,
                    name: crit.name,
                    category: crit.category,
                    categoryName: crit.categoryName,
                    type: 'Custom',
                    publicName: crit.name,
                    isChanged: false,
                    values
                }
            })
            return adapter.setAll(crits, {...state, lastCreatedId: id});
        }
        case ValuationProcessCriterionActionTypes.DeleteCustomCriterion: {
            return adapter.removeOne(action.payload.id, {...state})
        }
        case ValuationProcessCriterionActionTypes.ResetState: {
            return initialValuationProcessCustomCriterionState
        }
        default:
            return state;
    }
}
